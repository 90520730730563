<template>
    <Section
        width="lg"
    >
        <div v-if="$prismic.asHtml(slice.primary.title)" class="header" v-html="$prismic.asHtml(slice.primary.title)" />
        <p v-if="slice.primary.p" class="teaser">{{ slice.primary.p }}</p>
        <div class="table-container mobile-scroll-shadow">
            <table
                class="table-container__table"
            >
                <thead>
                    <tr>
                        <th />
                        <th v-for="card in allCards" :key="`logo${card.id}`">
                            <nuxt-link :to="$prismic.linkResolver(card)">
                                <prismic-image :img="card.data.card_image" w="200" />
                            </nuxt-link>
                        </th>
                    </tr>
                    <tr>
                        <th>{{ $translate('credit_card', 'Kreditkort') }}</th>
                        <th v-for="card in allCards" :key="`title${card.id}`">
                            {{ card.data.title }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="!slice.primary.disable_general_info">
                        <tr>
                            <th>{{ $translate('yearly_fee', 'Årsavgift') }}</th>
                            <td 
                                v-for="(card, index) in allCards" 
                                :key="index" 
                                :class="{ 
                                    highlight: checkLowest('yearlyCost', card)
                                }" 
                                v-html="card.model.yearlyCostHtml" 
                            />
                        </tr>
                        <tr>
                            <th>{{ $translate('effective_rate', 'Effektiv ränta') }}</th>
                            <td 
                                v-for="(card, index) in allCards" 
                                :key="index" 
                                :class="{ 
                                    highlight: checkLowest('minEffectiveInterestPossible', card) && card.model.isCredit
                                }" 
                                v-html="effectiveInterest(card)" 
                            />
                        </tr>
                        <tr>
                            <th>{{ $translate('max_credit', 'Max kredit') }}</th>
                            <td 
                                v-for="card in allCards" 
                                :key="`maxCredit${card.id}`" 
                                :class="{ 
                                    highlight: checkHighest('maxCredit', card) && card.model.isCredit 
                                }" 
                                v-html="maxCredit(card)"
                            /> 
                        </tr>
                    </template>
                    <template v-if="slice.primary.table_type === 'fees'">
                        <Fees 
                            v-for="(fee, index) in fees"
                            :key="index"
                            :fee="fee"
                            :all-cards="allCards"
                            :check-lowest="checkLowest"
                        />
                    </template>
                    <template v-else-if="slice.primary.table_type === 'mobile payment'">
                        <MobilePayments 
                            :all-cards="allCards"
                        />
                    </template>
                    <template v-else-if="slice.primary.table_type === 'insurances'">
                        <Insurances 
                            v-for="(insurance, index) in insurancesToShow" 
                            :key="index"
                            :insurance="insurance"
                        />
                    </template>
                    <template v-else-if="slice.primary.table_type === 'pros&cons'">
                        <Pros 
                            :all-cards="allCards"
                        />
                        <Cons 
                            :all-cards="allCards"
                        />
                    </template>
                    <FreeText 
                        v-for="(row, index) in customContent" 
                        :key="index"
                        :row="row"
                    />
                    <tr>
                        <th>{{ $translate('ct_read_more_th', 'Läs mer') }}</th>
                        <td v-for="card in allCards" :key="`more${card.id}`">
                            <nuxt-link class="button-section btn btn-outline-green btn-sm text-green" :to="$prismic.linkResolver(card)">    
                                {{ $translate('ct_details', 'Detaljerad info') }}
                            </nuxt-link>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ $translate('ct_to_application_th', 'Ansök') }}</th>
                        <td v-for="card in allCards" :key="`apply${card.id}`">
                            <ButtonTarget
                                v-if="card.data.redirect_enabled"
                                class="button-section"
                                :link="card"
                                :text="$translate('ct_to_application', 'Ansök')"
                                background="green"
                                size="sm"
                            />
                            <span v-if="card.model.complianceExample" class="text-compliance">
                                {{ card.model.complianceExample }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div 
            v-if="$validateText(slice.primary.footer_text)"
            class="rich-text footer-text"
            v-html="$prismic.asHtml(slice.primary.footer_text)"
        />
    </Section>
</template>
<script>
import Fees from '@/components/comparison-table/fees/Fees.vue';
import feesMixins from '@/components/comparison-table/fees/fees-mixins.js';

import FreeText from '@/components/comparison-table/free-text/FreeText.vue';
import freeTextMixins from '@/components/comparison-table/free-text/free-text-mixins.js';

import Insurances from '@/components/comparison-table/insurances/Insurances.vue';
import insurancesMixins from '@/components/comparison-table/insurances/insurances-mixins.js';

import MobilePayments from '@/components/comparison-table/mobile-payments/MobilePayments.vue';

import Pros from '@/components/comparison-table/pros-and-cons/Pros.vue';
import Cons from '@/components/comparison-table/pros-and-cons/Cons.vue';


export default {
    components: {
        Insurances,
        MobilePayments,
        FreeText,
        Pros,
        Cons,
        Fees,
    },
    mixins: [
        feesMixins,
        freeTextMixins,
        insurancesMixins
    ],
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        allCards() {
            return this.slice.items
                .map(item => item.credit_card)
                .filter(card => card.id)
                .map(card => {
                    const c = Object.assign({}, this.$store.state.cards[card.id]);
                    c.model = new this.$models.CreditCard(c);
                    return c;
                });
        },
        lowest () {
            const lowestKeys = {
                minEffectiveInterestPossible: undefined,
                overdraftFee: undefined,
                latePaymentFee: undefined,
                yearlyCost: undefined,
                reminderFee: undefined,
                aviFeeEInvoice: undefined,
                penaltyInterest: undefined,
                currencyExchangeFeePercentage: undefined,
            };
            let cardCategoryValues = [];
            const lowCheckers = Object.keys(lowestKeys);
            lowCheckers.forEach(category => {
                this.allCards.forEach(card => {
                    if (category === 'yearlyCost' && card.model.hasFirstYearOffer) {
                        cardCategoryValues.push(card.model.firstYearCost);
                    }
                    else if (category === 'minEffectiveInterestPossible' && card.model.isDebit) {
                        return false;
                    } 
                    else return cardCategoryValues.push(card.model[category]); 
                });
                lowestKeys[category] = Math.min(...cardCategoryValues);
                cardCategoryValues = [];
            });
            return lowestKeys;
        },
        highest () {
            const highestKeys = {maxCredit: undefined};
            let cardCategoryValues = [];
            const highCheckers = Object.keys(highestKeys);
            highCheckers.forEach(category => {
                this.allCards.forEach(card => {
                    cardCategoryValues.push(card.model[category]); 
                });
                highestKeys[category] = Math.max(...cardCategoryValues);
                cardCategoryValues = [];
            });
            return highestKeys;
        },
    },
    methods: {
        checkLowest (key, card) {
            if (key === 'yearlyCost' && card.model.hasFirstYearOffer) {
                return card.model.firstYearCost <= this.lowest[key];
            }
            return card.model[key] <= this.lowest[key];
        },
        checkHighest (key, card) {
            return card.model[key] >= this.highest[key];
        },
        maxCredit(card) {
            if (card.model.isCredit) {
                return card.model.getMaxCreditString(this.$translate('no_max_credit', 'Ingen kreditgräns'));
            }
            return `<span class='debet-bg'>${this.$translate('debit_card', 'Debitkort')}</span>`;
        },
        effectiveInterest(card) {
            if (card.model.isCredit) {
                return card.model.effectiveInterestString;
            }
            return `<span class='debet-bg'>${this.$translate('debit_card', 'Debitkort')}</span>`;
        },
    },
};
</script>
<style lang='scss' scoped>
    ::v-deep {
        .header {
            margin-bottom: 1rem;
        }
        .free-text {
            display: flex;
            align-items: center;
        }
        .free-text-data {
            margin-left: 0.5rem;
        }
        .highlight {
            color: #00c985;
        }
        .debet-bg {
            font-weight: bold;
        }
        .teaser {
            margin-bottom: 25px;
        }
        .table-container::-webkit-scrollbar {
            height: 5px;
            width: 5px;
            background: hsla(0,0%,96.9%,.7);
        }

        .table-container::-webkit-scrollbar-track {
            background: inherit;
        }

        .table-container::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: lightgrey;
        }

        .table-container::-webkit-scrollbar-thumb:hover {
            background: #f0617a;
        }
        .button-section {
            display: block;
        }
        .btn-outline-green {
            outline: 2px solid $green;
            &:hover {
                background-color: $green;
            }
        }
        .text-green {
            color: $green;
             &:hover {
                color: $white;
            }
        }
        td {
            vertical-align: baseline;
            font-family: $fontText;
        }
        li {
            display: flex;
            font-weight: normal;
            text-align: left;
            align-items: baseline;
            gap: 0.5rem;
            padding-bottom: 0.5rem; 
        }
        .check-mark {
            color: #00c985;
            font-size: 0.85rem;
        }
        .cross-mark {
            color: #d21433;
            font-size: 0.85rem;
        }
        .table-container {
            overflow: auto;
            margin-top: 1.5rem;
            @include border-radius(15px);
            @include border(all,1px,rgba(211, 211, 211, 0.3));
            box-shadow: $bxs;
        }
        table {
            table-layout: fixed;
            min-width: 100%;
        }     
        tr {
            @include border(bottom,1px,rgba(211, 211, 211, 0.3));
            &:last-child { border: 0; }
            &:nth-child(even) {
                background: hsla(0,0%,96.9%,.9);
            }
        }
       
        td, th {
            min-width: 120px;
            padding: 16px;
            @include font-size(14px,8px);
            @include border(right,1px,rgba(211, 211, 211, 0.3));
            &:last-child { border: 0; }
            font-weight: normal;
            box-sizing: content-box;
        }
        span.standard-yearly-cost {
            color: red;
            margin-left: 5px;
            text-decoration: line-through;
        }
        th {
            font-family: $fontHeader;
            text-align: left;
            font-weight: bold;
            img {
                width: 120px;
            }
        }
        th:has(img) {
            text-align: center;
        }
        tr th:first-child {
            min-width: 90px;
        }
        .btn {
            border-radius: 3px;
        }
        @include device(desktop) {
            table {
                width: 100%;
            }
            .table-container {
                margin-top: 3rem;
            }
            th {
                @include font-size(16px,8px);
                img {
                    width: 200px;
                }
            }
        }

        .footer-text {
            margin-top: 2rem;

            & * {
                @include font-size(14px,8px);
            }
        }   
   }
</style>