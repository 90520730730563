<template>
    <Section
        width="sm"
        :label="slice.slice_label"
    >
        <h2 class="header">{{ $prismic.asText(slice.primary.header) }}</h2>
        <div
            class="rich-text"
            v-html="$prismic.asHtml(slice.primary.text)"
        />
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .rich-text,
    h2 {
        text-align: center;
    }
</style>
