<template>
    <Section width="md">
        <div 
            v-if="slice.primary.h2 && $validateText(slice.primary.text)" 
            class="section-header"
        >
            <h2 v-if="slice.primary.h2">
                {{ slice.primary.h2 }}
            </h2>
            <div 
                v-if="$validateText(slice.primary.text)"
                class="rich-text" 
                v-html="$prismic.asHtml(slice.primary.text)"
            />
        </div>
        <div class="grid">
            <div class="highlighted-container">
                <selected-post-item 
                    v-for="(post, index) in highlightedPosts.slice(0, 1)" 
                    :key="index" 
                    :post="post"
                    class="highlighted"
                />
            </div>
            <div class="container">
                <selected-post-item 
                    v-for="(post, index) in highlightedPosts.slice(1, 4)" 
                    :key="index" 
                    :post="post"
                    class="default"
                />
            </div>
        </div>
    </Section>
</template>
<script>
import SelectedPostItem from '@/components/SelectedPostItem.vue';
export default {
    components: { SelectedPostItem },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            highlightedPosts: [],
        };
    },
    async fetch() {
        this.highlightedPosts = (await this.$prismic.api.getByIDs(this.postIds,
            { 
                lang: process.env.PRISMIC_LANG, 
                pageSize: 4
            }
        )).results;
    },
    fetchKey() {
        return `highlighted-posts-${this.slice.id}`;
    },
    computed: {
        postIds() {
            return this.slice.items.filter(item => item.post.id)
                .map(item => item.post.id);
        }
    }
};
</script>
<style lang="scss" scoped>
    section::v-deep {
        .section-header {
            @include spacing(padding, 5, bottom);
        }
        .grid {
            @include grid(1, 20px);
            
            @include device(pad) {
                @include grid(2, 20px);
            }

            .container {
                @include grid(1, 20px);

                @include device(pad) {
                    @include grid(1, 20px);
                }
            }

            .highlighted-container, 
            .container {
                &-box {
                    color: $font;
                    font-family: $fontText;
                    box-shadow: $bxs;
                    @include border-radius(5px);
                    transition: .15s ease-in-out;
                    &:hover {
                        .container-box-body {
                            .read-more {
                                color: $pink;
                            }
                        }
                    }

                    &-body {
                        @include spacing(padding, 5);
                        .body-title {
                            font-weight: bold;
                            @include font-size(24px,8px);
                        }
                        .read-more {
                            font-weight: bold;
                            &:hover {
                                color: $pink;
                            }
                        }
                        i {
                            color: $pink;
                            transition: .3s ease-in-out;
                            margin-left: 10px;
                        }
                    }
                }

                &-box.default {
                    @include grid(1,0);
                    .post-image {
                        @include border-radius(5px, (top-right, top-left));
                    }
                    .container-box-body {
                        display: flex;
                        flex-direction: column;
                        row-gap: 2rem;
                    }
                    @include device(pad) {
                        @include grid(2,0);
                        .post-image {
                            object-fit: cover;
                            height: 100%;
                            @include border-radius(5px, (bottom-left, top-left));
                            @include border-radius(0, (top-right));
                        }
                        
                        .container-box-body {
                            @include flex(center, start);
                        }
                    }
                }

                &-box.highlighted {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    
                    .post-image {
                        @include border-radius(5px, (top-right, top-left));
                    }
                    .container-box-body {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        row-gap: 2rem;
                        height: 100%;
                        
                        .body-title {
                            @include device(pad) {
                                @include font-size(32px,8px);
                            }
                        }
                    }
                }
            }
        }
    }
</style>