<template>
    <Section width="lg">
        <HeaderSection :header="slice.primary.header" />

        <ul>
            <LatestNewsLink
                v-for="(post, index) in posts"
                :key="index"
                :post="post"
            />
        </ul>
    </Section>
</template>

<script>
import LatestNewsLink from './LatestNewsLink.vue';
export default {
    components: { LatestNewsLink },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            posts: [],
        };
    },
    async fetch() {
        const predicates = [
            this.$prismic.predicates.at('document.type', 'post'),
        ];

        this.posts = (await this.$prismic.api.query(predicates,
            { 
                orderings: '[document.first_publication_date desc]',
                lang: process.env.PRISMIC_LANG,
                pageSize: 3
            }
        )).results;
    },
    fetchKey() {
        return `latest-posts-${this.slice.id}`;
    }
};

</script>

<style lang="scss" scoped>
    ul {
        @include grid(1, 20px);

        @include device(pad) {
            @include grid(2, 20px);
        }
        @include device(desktop) {
            @include grid(3, 20px);
        }
    }
</style>
