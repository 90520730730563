<template>
    <div
        v-if="card"
        class="card-alternative"
    >
        <PrismicImage
            :img="card.data.card_image"
            w="190"
            h="120"
        />

        <div class="card-alternative__content">
            <span class="content-header">{{ card.data.title }}</span>

            <CardStarsWithCount
                :data="model._ratings"
            />
        </div>

        <div class="card-alternative__buttons">
            <ButtonTarget
                :link="card"
                placement="alternative"
                :text="$translate('cta_credit_card_list', 'Till ansökan')"
                icon="fas fa-lock white"
                background="green"
                size="md"
            />

            <ButtonNuxt
                :link="card"
                :text="$translate('cta_read_more', 'Läs mer')"
                icon="fas fa-arrow-right"
                background="transparent"
                size="sm"
            />
        </div>
        <div v-if="model.complianceExample" class="text-compliance">
            {{ model.complianceExample }}
        </div>
    </div>
</template>

<script>
import CardStarsWithCount from '@/components/stars/StarsWithCount.vue';
export default {
    components: { CardStarsWithCount },
    props: {
        id: {
            type: String,
            required: false,
            default: String
        }
    },
    computed: {
        fallbackCard() {
            return this.$getOrderedList(
                Object.values(this.$store.state.cards)
                    .filter(card => card.data.redirect_enabled)
            )[0];
        },
        alternativeCard() {
            return this.$store.state.cards[this.id];
        },
        card() {
            if (this.id && this.alternativeCard.data.redirect_enabled) {
                return this.alternativeCard;
            }
            return this.fallbackCard;
        },
        model() {
            return new this.$models.CreditCard(this.card);
        }
    }
};
</script>

<style lang="scss" scoped>
    .card-alternative {
        width: 100%;
        @include flex;
        @include spacing(padding,6);
        background: $white;
        box-shadow: $bxs;
        @include border-radius(3px);

        &__content {
            margin-top: 20px;
            text-align: center;
            @include grid(1, 10px);

            @include device(pad) {
                margin-top: 0;
                text-align: left;
                flex: 1;
                padding-left: 20px;
            }

            .content-header {
                @include font-size(28px);
                font-family: $fontHeader;
            }
        }

        &__buttons {
            width: 100%;
            @include spacing(margin, 6, top);
            @include grid(1, 10px);

            @include device(pad) {
                @include grid(2, 20px);
            }

            .btn { @include flex; }
        }
    }
</style>
