<template>
    <div
        v-animate="{ threshold: .3, animation: 'up-slide' }"
        class="question-container"
    >
        <span class="question-header">{{ header }}</span>
        <div class="question-options">
            <slot />
        </div>
        <div 
            v-if="errorText && showErrors && ! activeValue"
            class="question-error"
        >
            <span>{{ errorText }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        header: {
            type: String,
            required: true
        },
        activeValue: {
            type: [String, Number],
            required: false,
            default: null
        },
        errorText: {
            type: String,
            required: false,
            default: null
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>