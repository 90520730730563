<template>
    <tr>
        <th>{{ row.tableHeader }}</th>
        <td v-for="(value, i) in row.tableValues" :key="i">
            <div class="free-text">                    
                <i v-if="value.checkMark !== 'none'" :class="value.checkMark === 'check-mark' ? 'fas fa-check-circle check-mark' : 'fas fa-times-circle cross-mark'" />
                <div class="free-text-data" v-html="$prismic.asHtml(value.data)" />
            </div>
        </td>
    </tr>
</template>
<script>
export default {
    props: {
        row: {
            type: Object,
            required: true
        },
    }
};
</script>

