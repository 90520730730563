<template>
    <div class="expander">
        <div 
            class="expander-button"
            :class="{'expanded': show}"
            role="button"
            @click="show = !show"
        >
            <i 
                v-if="$validateText(titleIcon)" 
                :class="titleIcon"
            />
            <span class="expander-title">{{ title }}</span>
            <i :class="[{'rotate-active': show}, expanderIcon]" />
        </div>
        <div :class="{'expanded': show}" class="expander-content-wrapper">
            <div class="overflow-hidden">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        titleIcon: {
            type: String,
            required: false,
            default: ''
        },
        expanderIcon: {
            type: String,
            required: false,
            default: 'fas fa-chevron-down rotate expander-icon'
        }
    },
    data() {
        return {
            show: false
        };
    }
};
</script>

<style lang="scss" scoped>
    .expander-button {
        cursor: pointer;
    }
    .expander-content-wrapper {
        margin-top: 10px;
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 400ms ease-in-out;
        &.expanded {
            grid-template-rows: 1fr;
        }
        .overflow-hidden {
            overflow: hidden;
        }
    }
</style>