<template>
    <tr>
        <th>{{ $translate('pros', 'Fördelar') }}</th>
        <td v-for="card in allCards" :key="`${card.id}`">
            <ul>
                <li v-for="(pro, index) in card.data.pros_condensed" :key="index">
                    <i v-if="pro.pro" class="fas fa-check-circle check-mark" />
                    <span>{{ pro.pro[0].text }}</span>
                </li>
            </ul>    
        </td>
    </tr>
</template>
<script>
export default {
    props: {
        allCards: {
            type: Array,
            required: true
        }
    },
};
</script>