<template>
    <Section 
        v-if="$validateText(slice.primary.content)"
        :width="sectionWidth || 'sm'"
        class="info-box"
        :class="boxStyle"
    >
        <div 
            class="rich-text info-box-content"
            v-html="$prismic.asHtml(slice.primary.content)"
        />
        <i class="fa-solid info-box-icon" :class="icon" />
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        sectionWidth() {
            return this.slice.primary.section_width;
        },
        boxStyle() {
            return this.slice.primary.content_box_style;
        },
        icon() {
            switch (this.slice.primary.content_box_style) {
            case 'warning':
                return 'fa-exclamation-circle';
            case 'danger':
                return 'fa-exclamation-triangle';
            case 'success':
                return 'fa-check-circle';
            default:
                return 'fa-info-circle';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .info-box::v-deep {
        .section-inner {
            position: relative;
            .info-box-content {
                @include spacing(padding, 4);
                @include border-radius(5px);
            }
            .info-box-icon {
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-40%,-40%);
                font-size: calc(1.325rem + .9vw);

                &:before {
                    background-color: $white;
                    @include spacing(padding, 0.5);
                }
            }
        }
        &.info {
            .info-box-content {
                @include border(all, 2px, $blue);
            }
            .info-box-icon {
                color: $blue;
            }
        }
        &.warning {
            .info-box-content {
                @include border(all, 2px, #FFB81E);
            }
            .info-box-icon {
                color: #FFB81E;
            }
        }
        &.danger {
            .info-box-content {
                @include border(all, 2px, $red);
            }
            .info-box-icon {
                color: $red;
            }
        }
        &.success {
            .info-box-content {
                @include border(all, 2px, $green);
            }
            .info-box-icon {
                color: $green;
            }
        }
    }
</style>