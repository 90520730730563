<template>
    <Section width="lg" :style="textAlign">
        <div 
            v-for="(item, index) in items"
            :key="index"    
            class="container"
        >
            <span class="container-header">{{ $prismic.asText(item.header) }}</span>

            <div
                class="container-content rich-text"
                v-html="$prismic.asHtml(item.text)"
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        items() {
            return this.slice.items
                .filter(item => this.$validateText(item.text));
        },
        textAlign() {
            if (this.slice.primary.text_align) {
                return `text-align: ${this.slice.primary.text_align};`;
            }
            return 'text-align: center;';
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {

        .section-inner {
            @include flex(start, stretch);
            box-shadow: $bxs;
            @include border-radius(3px);
            overflow: hidden;

            .container {
                width: 100%;
                display: flex;
                flex-direction: column;
                @include spacing(padding, 5);
                @include device(desktop) {
                    width: 50%;
                    @include spacing(padding, 8);
                }

                &-header {
                    font-family: $fontHeader;
                    @include font-size(32px, 10px);
                    @include spacing(margin,6,bottom);
                }

                &-content {
                    p:first-child { margin-top: 0; }
                    p:last-child { margin-bottom: 0; }
                }

                &:nth-child(odd) {
                    background-color: $lightblue;
                }
                &:nth-child(even) {
                    background-color: $lightpink;
                }

                @include device(desktop) {
                    &:nth-child(4n+1),
                    &:nth-child(4n+4) {
                        background-color: $lightblue;
                    }
                    &:nth-child(4n+2),
                    &:nth-child(4n+3) {
                        background-color: $lightpink;
                    }
                }
            }
        }
    }
</style>
