<template>
    <Section
        width="lg"
        :label="slice.slice_label"
    >
        <HeaderSection
            :header="slice.primary.header"
            :teaser="slice.primary.text"
        />

        <ul class="list-page-links">
            <PageLink
                v-for="(iterator, index) in slice.items.filter($filterEmptyRelations)"
                :key="index"
                :link="iterator.link"
                :text="$prismic.asText(iterator.text)"
                :image="iterator.link.data.icon"
            />
        </ul>
    </Section>
</template>
<script>
import PageLink from '@/components/PageLink.vue';
export default {
    components: { PageLink },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>
<style lang="scss" scoped>
section::v-deep {
    ul {
        li {
            background: $lightblue;

            &:hover {
                span { color: $blue; }
            }
        }
    }
}
</style>
