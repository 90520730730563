<template>
    <div v-if="settings.enable_trust_us_card_list" class="trust-us">
        <expander 
            class="trust-us-expander" 
            :title="settings.trust_us_expander_title"    
            title-icon="fa-solid fa-shield-check fa-lg title-icon"
        >
            <div 
                v-if="$validateText(settings.trust_us_expander_text)" 
                class="rich-text trust-us-rich-text"
                v-html="$prismic.asHtml(settings.trust_us_expander_text)" 
            />
        </expander>
    </div>
</template>

<script>
import Expander from '@/components/Expander.vue';
export default {
    components: { Expander },
    computed: {
        settings() {
            return this.$store.state.settings;
        }
    }
};
</script>
<style lang="scss" scoped>
    .trust-us::v-deep {
        margin-top: 1rem;
        .expander:has(.expander-button.expanded) {
            @include border-radius(5px);
            background-color: $white;
            box-shadow: $bxs;
        }
        .expander {
            padding: 16px 12px;
            @include device(pad) {
                padding: 24px 32px;
            }
            .expander-button {
                .title-icon {
                    color: $green;
                    margin-right: 0.25rem;
                }
                .expander-title {
                    font-family: $fontText;
                    font-weight: bold;
                }
                .expander-icon {
                    margin-left: 0.5rem;
                }
            }
            .trust-us-rich-text {
                margin-top: 0.5rem;
                p:first-child {
                    margin-top: 0;
                }
                * {
                    font-size: 14px;
                }
            }
        }
    }
</style>