<template>
    <tr>
        <th>{{ insurance.title }}</th>
        <td v-for="(cardInsurance, x) in insurance.exist" :key="x">
            <i :class="cardInsurance ? 'fas fa-check-circle check-mark' : 'fas fa-times-circle cross-mark'" />
        </td>
    </tr>
</template>
<script>
export default {
    props: {
        insurance: {
            type: Object,
            required: true
        },
    }
};
</script>