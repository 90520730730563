<template>
    <Section
        width="md"
        :label="slice.slice_label"
        class="plain-text-image"
    >
        <div class="container">
            <div 
                v-if="$validateText(slice.primary.text)" 
                class="rich-text"
                v-html="$prismic.asHtml(slice.primary.text)"
            />

            <ButtonNuxt
                v-if="slice.primary.button_text"
                :link="slice.primary.button"
                :text="slice.primary.button_text"
                size="md"
                icon="fas fa-arrow-right"
                background="pink"
            />
        </div>

        <div class="container">
            <PrismicImage
                :img="slice.primary.image"
                w="530"
            />
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>
<style lang="scss" scoped>
section.plain-text-image::v-deep {
    .section-inner {
        @include grid(1, 20px);

        @include device(pad) {
            @include grid(2, 50px);
        }

        img {
            max-width: 100%;
        }

        .container {
            max-width: 100%;

            &:first-child {
                @include flex(start);

                p { @include spacing(margin,8,(top, bottom));}

                .btn { max-width: 300px; }
            }

            &:last-child { 
                @include flex(end); 
                @include device(mobile) {
                    @include flex(start);
                }
            }
            :last-child p {
                @include spacing(margin,0,(bottom));
            }
        }
    }
}
</style>
