<template>
    <Section :width="width" :class="{'reduced-top-spacing': slice.primary.reduce_top_spacing}">
        <a 
            v-if="isWebLink"
            class="btn btn-md button" 
            :class="`btn-${buttonColor}`" 
            :href="slice.primary.link.url" 
            target="_blank"
        >
            {{ slice.primary.link_text }}
        </a>
        <nuxt-link
            v-else-if="!isOutLink"
            :to="$prismic.linkResolver(slice.primary.link)"
            class="btn btn-md button"
            :class="`btn-${buttonColor}`"
        >
            {{ slice.primary.link_text }}
        </nuxt-link>
        <ButtonTarget
            v-else-if="outLinkEnabled"
            :link="link"
            :text="slice.primary.link_text"
            :background="buttonColor"
            size="md"
            class="button"
        />
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            link: this.slice.primary.link,
        };
    },
    computed: {
        width() {
            return this.slice.primary.width ?? 'sm';
        },
        buttonColor() {
            return this.slice.primary.button_style ?? 'pink';
        },
        isWebLink() {
            return this.slice.primary.link.link_type === 'Web';
        },
        isOutLink() {
            return this.slice.primary.outlink;
        },
        outLinkEnabled() {
            if (this.link.type === 'lender') {
                return Boolean(this.link.id);
            }
            if (this.link.type === 'credit_card') {
                return this.$store.state.cards[this.link.id].data.redirect_enabled;
            }
            return false;
        }
    },
};
</script>

<style lang="scss" scoped>
    .section {
        padding-top: 0;
        padding-bottom: 40px;
        &.reduced-top-spacing {
            margin-top: -20px;
            @include device(pad) {
                margin-top: -40px;
            }
        }
        .button {
            display: block;
            width: 100%;
        }
    }
</style>