<template>
    <div class="slider-container">
        <div class="slider-values">
            <label>{{ label }}</label>
            <span>{{ formatValue(sliderValue) }}</span>
        </div>
        <range-slider
            v-model="sliderValue"
            class="slider"
            :min="minValue"
            :max="maxValue"
            :step="increment"
        />
        <div class="slider-values slider-constraints-values">
            <span>{{ formatValue(minValue) }}</span>
            <span>{{ formatValue(maxValue) }}</span>
        </div>
    </div>
</template>
 
<script>
import RangeSlider from 'vue-range-slider';
import 'vue-range-slider/dist/vue-range-slider.css';
 
export default {
    components: {
        RangeSlider
    },
    props: {
        label: {
            type: String,
            required: false,
            default: String
        },
        startValue: {
            type: Number,
            required: false,
            default: 0
        },
        minValue: {
            type: Number,
            required: false,
            default: 0
        },
        maxValue: {
            type: Number,
            required: false,
            default: 100
        },
        increment: {
            type: String,
            required: false,
            default: '1'
        },
        formatValue: {
            type: Function,
            required: false,
            default: (value) => value
        }
    },
    data () {
        return {
            sliderValue: this.startValue,
        };
    },
    watch: {
        sliderValue() {
            this.$emit('updateValue', this.sliderValue);
        }
    }
};
</script> 


<style lang="scss" scoped>
    .slider-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .slider-values {
        display: flex;
        justify-content: space-between;
        &.slider-constraints-values {
            opacity: 0.5;
        }
    }
    .slider ::v-deep {
        display: block;
        width: 100%;
        padding: 0 1px;
        
        .range-slider-fill {
            background: $pink;
            height: 10px;
            border-radius: 5px;
        }

        .range-slider-rail {
            height: 10px;
            background: $lightgray;
            border-radius: 5px;
        }

        .range-slider-knob {
            height: 25px;
            width: 25px;
            border: 2px solid $white;
            background: $pink;
            box-shadow: none;
        }
    }
</style>