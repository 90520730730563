<template>
    <Section
        v-if="slice.items.length"
        :id="slice.primary.guide_category"
        class="guide"
        width="md"
    >
        <h2 v-if="$prismic.asText(slice.primary.header).length > 0">{{ $prismic.asText(slice.primary.header) }}</h2>

        <ul class="list">
            <ListItem
                v-for="(iterator, index) in list"
                :key="index"
                v-addClass="{threshold: .5, class: 'animated'}"
                :page="iterator.link"
            />
        </ul>

        <div
            v-if="totalPages > 3"
            class="list-buttons"
        >
            <Button
                v-if="limit === 3"
                :text="$translate('show_more', 'Visa mer')"
                icon="fas fa-chevron-down"
                background="pink"
                size="md"
                @click="limit = totalPages"
            />
            <Button
                v-else
                :text="$translate('show_less', 'Visa mindre')"
                icon="fas fa-chevron-up"
                background="pink"
                size="md"
                @click="limit = 3"
            />
        </div>
    </Section>
</template>

<script>
import ListItem from './ListItem.vue';
export default {
    components: { ListItem },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            limit: 3
        };
    },
    computed: {
        totalPages() {
            return this.slice.items.length;
        },

        list() {
            return this.slice.items.filter((item, index) => index <= this.limit)
        }
    }
};
</script>

<style lang="scss" scoped>
    section {
        h2 { @include spacing(margin, 8, bottom); }

        .list {
            @include grid(1,40px);

            @include device(pad) {
                @include grid(3,20px);
            }

            @include device(desktop) {
                @include grid(4,20px);
            }

            &-item {
                opacity: 0;
            }

            &-buttons {
                width: 100%;
                @include flex(start);
                @include spacing(margin, 6, top);
            }

            .animated {
                animation: stagger .3s ease-in-out forwards;

                &:nth-child(2) { animation-delay: .1s; }
                &:nth-child(3) { animation-delay: .2s; }
                &:nth-child(4) { animation-delay: .3s; }
            }


            @keyframes stagger {
                from {
                    opacity: 0;
                    transform: translateY(50px);
                }
                to {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }
</style>
