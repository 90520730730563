<template>
    <div class="input-container">
        <label :for="formKey">{{ label }}</label>
        <input
            v-model="input"
            :type="type"
            :name="formKey"
            :placeholder="placeholder"
        >
        <div
            v-if="error"
            class="input-error"
        >
            {{ errorText }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        formKey: {
            type: String,
            required: false,
            default: ''
        },
        label: {
            type: String,
            required: true
        },
        value: {
            type: [String, Number, null],
            required: false,
            default: null
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        errorText: {
            type: String,
            required: false,
            default: String
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        type: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            input: this.value
        };
    },
    watch: {
        input() {
            this.$emit('set', this.formKey, this.input);
        }
    },
    mounted() {
        this.$emit('set', this.formKey, this.input);
    },
};
</script>

<style lang="scss" scoped>
    .input-container {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(1,1fr);
        font-family: $fontText;
        input {
            font-size: 14px;
        }
        .input-error {
            color: #8b0000;
            background: rgba(179,0,0,.15);
            padding: 7px;
            font-size: 13px;
            margin-top: -5px;
        }
    }
</style>
