<template>
    <Section
        width="md"
        class="others"
    >
        <h2>{{ $translate('card_page_other_cards_header', 'Andra kort') }}</h2>

        <div class="container">
            <CardData
                v-for="card in slice"
                :id="card.card.id"
                :key="card.card.id"
                v-animate="{threshold: .5, animation: 'pop'}"
            />
        </div>
    </Section>
</template>

<script>
import CardData from '@/components/cards/CardData';
export default {
    components: { CardData },
    props: {
        slice: {
            type: Array,
            required: true,
            default: () => []
        },
        header: {
            type: Object,
            required: false,
            default: () => {}
        },
        img: {
            type: Object,
            required: false,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    background: $lightgray;
    margin: 40px 0;
    @include device(pad) {
        margin: 48px 0;
    }

    h2 {
        @include spacing(margin, 8, bottom);
        width: 100%;
        text-align: center;
    }

    .container {
        @include grid(1, 40px);

        @include device(pad) {
            @include grid(3, 40px);
        }
    }
}
</style>
