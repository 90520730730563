<template>
    <nuxt-link
        :to="$prismic.linkResolver(card)"
    >
        <PrismicImage
            :img="card.data.card_image"
            w=""
        />
    </nuxt-link>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: false,
            default: String
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.id];
        }
    }
};
</script>
