<template>
    <Section width="md">
        <div
            v-for="(item, index) in slice.items"
            :key="index"
            class="container"
        >
            <span class="container-number">{{ item.number }}</span>
            <span class="container-text">{{ $prismic.asText(item.text) }}</span>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        background: $lightblue;
        margin: 40px 0;
        @include device(pad) {
            margin: 48px 0;
        }
        
        .section-inner {
            @include flex(start, start);
            gap: 1.5rem;

            .container {
                @include grid(1, 20px);
                text-align: center;
                @include spacing(padding, 6, left);
                @include spacing(padding, 6, right);
                width: 100%;

                &-number {
                    @include font-size(32px);
                    font-family: $fontHeader;
                    color: $pink;
                }

                &-text {
                    font-family: $fontText;
                    text-transform: uppercase;
                    @include font-size(16px);
                }

                @include device(pad) {
                    width: auto;
                    flex: 1;

                    &-number {
                        @include font-size(44px)
                    }
                    &-text {
                        @include font-size(18px)
                    }
                }
            }
        }
    }
</style>
