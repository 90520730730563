<template>
    <div v-if="enabled">
        <Stars class="width-full" :rating="Math.round(data.average_score)" />
        <span v-if="showCount">{{ data.ratings_count }} {{ $translate('user_reviews', 'recensioner') }}</span>
    </div>
</template>

<script>
import Stars from './Stars.vue';
export default {
    components: { Stars },
    props: {
        data: {
            type: Object,
            required: false,
            default: () => {}
        },
        showCount: {
            type: Boolean,
            required: false,
            default: Boolean
        }
    },
    computed: {
        enabled() {
            return ! this.$isNorway() && this.data;
        }
    }
};
</script>

<style lang="scss" scoped>
    span {
        font-family: $fontText;
        margin-top: 10px;
        display: block;
    }
</style>
