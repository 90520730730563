<template>
    <Section class="banner">
        <div class="container">
            <div class="container-inner">
                <HeaderBanner
                    :header="$prismic.asText(slice.primary.h1)"
                    :teaser="slice.primary.text"
                    align="left"
                />
            </div>
        </div>

        <div class="container">
            <transition name="slide-left" appear>
                <div class="container-inner">
                    <h3>{{ card.data.title }}</h3>
                    <nuxt-link :to="$prismic.linkResolver(card)">
                        <PrismicImage
                            :img="card.data.card_image"
                            w="250"
                            h="159"
                            :lazy="false"
                        />
                    </nuxt-link>
                    
                    <CardStarsWithCount :data="model._ratings" />

                    <ButtonTarget
                        v-if="card.data.redirect_enabled"
                        :link="card"
                        placement="featured"
                        :text="$translate('cta_credit_card_list', 'Till ansökan')"
                        icon="fas fa-lock white"
                        background="green"
                        size="md"
                    />

                    <span
                        v-if="card.data.redirect_enabled"
                        class="text-subtitle"
                    >
                        {{ $getApplySecurelyString(card) }}
                    </span>

                    <ListCardInfo :data="card" :model="model" />
                    <span v-if="model.complianceExample" class="text-compliance">
                        {{ model.complianceExample }}
                    </span>
                </div>
            </transition>
        </div>
    </Section>
</template>

<script>
import CardStarsWithCount from '@/components/stars/StarsWithCount.vue';
import ListCardInfo from '@/components/slices/lists/ListCardInfo.vue';
export default {
    components: { ListCardInfo, CardStarsWithCount },
    props: {
        slice: {
            type: Object,
            required: true,
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.slice.primary.credit_card.id];
        },
        model() {
            return new this.$models.CreditCard(this.card);
        },
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        padding: 0;
        @include device(pad) {
            margin-bottom: 48px;
        }
        .section-inner {
            width: 100%;
            @include grid(1, 0px);

            @include device(pad) {
                @include grid(2, 0px);
            }

            .container {
                box-sizing: border-box;
                padding: 20px;
                padding-top: 40px;

                @include device(pad) {
                    padding-top: 80px;
                    padding-bottom: 80px;
                    padding-left: 40px;
                    padding-right: 40px;
                }

                &:first-child {
                    @include flex(end);
                    background: $white;

                    .container-inner {
                        max-width: 600px;
                    }
                }

                &:last-child {
                    background: $lightpink;

                    .container-inner {
                        @include spacing(padding,5);
                        @include grid(1, 20px);
                        text-align: center;
                        background: $white;
                        box-shadow: $bxs;
                        max-width: 500px;

                        @include device(mobile) {
                            ul li {
                                @include border(bottom);
                                padding: 12px 0;
                            }
                        }

                        span.text-subtitle { margin-top: -10px; }
                    }
                }
            }
        }
    }
</style>
