<template>
    <div id="detailsTable" class="table-container">
        <table class="table-container__table">
            <thead :class="{'sticky': duration > 12}">
                <tr>
                    <th>{{ $translate('month', 'Månad') }}</th>
                    <th>{{ $translate('calculator_details_payment_instance', 'Betalningstillfälle') }}</th>
                    <th>{{ $translate('calculator_details_debt_before_amortization', 'Skuld före amortering') }}</th>
                    <th>{{ $translate('calculator_details_amortization', 'Amortering') }}</th>
                    <th>{{ $translate('calculator_details_interest', 'Ränta') }}</th>
                    <th>{{ $translate('calculator_details_to_pay', 'Att betala') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="repaymentPeriodData in repaymentDetails" :key="repaymentPeriodData.repaymentPeriod">
                    <td> {{ repaymentPeriodData.month }} </td>
                    <td> {{ repaymentPeriodData.repaymentPeriod }} </td>
                    <td> {{ repaymentPeriodData.debtBeforeAmortization }} </td>
                    <td> {{ repaymentPeriodData.amortization }} </td>
                    <td> {{ repaymentPeriodData.interest }} </td>
                    <td> {{ repaymentPeriodData.toPay }} </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        duration: {
            type: Number,
            required: true
        },
        interest: {
            type: Number,
            required: true
        },
        credit: {
            type: Number,
            required: true
        }
    },
    computed: {
        repaymentDetails() {
            const getMonth = (repaymentPeriod) => {
                const monthNumber = (currentMonth + repaymentPeriod) % months.length;
                let month = months[monthNumber];
                if (repaymentPeriod === 1 || monthNumber === 0) {
                    month = month + ', ' + incrementingYear;
                    incrementingYear++;
                }
                return month;
            };

            const data = [];
            const currentMonth = new Date().getMonth();

            const monthlyInterestRate = (this.interest/100) / 12;
            const annuityMonthlyPayment = (monthlyInterestRate * this.credit) / (1 - (1 + monthlyInterestRate) ** -this.duration);

            const months = [
                this.$translate('january_short', 'Jan'), 
                this.$translate('february_short', 'Feb'), 
                this.$translate('march_short', 'Mar'),
                this.$translate('april_short', 'Apr'),
                this.$translate('may_short', 'Maj'), 
                this.$translate('june_short', 'Jun'), 
                this.$translate('july_short', 'Jul'),
                this.$translate('august_short', 'Aug'),
                this.$translate('september_short', 'Sep'),
                this.$translate('october_short', 'Okt'), 
                this.$translate('november_short', 'Nov'), 
                this.$translate('december_short', 'Dec')
            ];

            let incrementingYear = new Date().getFullYear();
            let debtBeforeAmortization = this.credit;

            for (let repaymentPeriod = 1; repaymentPeriod <= this.duration; repaymentPeriod++) {

                let interest = monthlyInterestRate * debtBeforeAmortization;
                
                // Annuity amortization
                let amortization = annuityMonthlyPayment - interest;
                let toPay = annuityMonthlyPayment;

                // Straight-line amortization
                if (this.$isSweden()) {
                    amortization = this.credit / this.duration;
                    toPay = amortization + interest;
                }

                data.push({
                    month: getMonth(repaymentPeriod),
                    repaymentPeriod: repaymentPeriod,
                    debtBeforeAmortization: this.$format.currency(debtBeforeAmortization),
                    amortization: this.$format.currency(amortization),
                    interest: this.$format.currency(interest),
                    toPay: this.$format.currency(toPay)
                });
                debtBeforeAmortization -= amortization;
            }
            return data;
        }
    },
};
</script>

<style lang="scss" scoped>
    .table-container {
        box-shadow: none;
        max-height: 60vh;
        border-radius: 0;
        overflow: auto;
        @include device(pad) {
            max-height: unset;
            overflow: unset;
        }
        table {
            width: 100%;
            thead {
                font-weight: bold;
                font-family: $fontHeader;
                th {
                    background: $pink;
                    color: $white;
                }
                &.sticky th{
                    position: sticky;
                    top: 0;
                    @media screen and (min-width: 1000px) {
                        top: $navHeightDesktop;
                    }
                }
            }
            tbody {
                font-family: $fontText;
                tr:nth-child(even) {
                    background: hsla(0,0%,96.9%,.9);
                }
            }
            th,td {
                text-align: left;
                @include spacing(padding, 2)
            }
        }
    }
</style>