<template>
    <li>
        <span>{{ $translate('mobile_payments') }}</span>
        <span class="icons">
            <i v-if="model.hasApplePay" class="fab fa-apple-pay" />
            <i v-if="model.hasGooglePay" class="fab fa-google-pay" />
            <span v-if="!(model.hasApplePay || model.hasGooglePay)">-</span>
        </span>
    </li>
</template>

<script>
export default {
    props: {
        model: {
            type: Object,
            required: true
        }
    }
};
</script>
<style lang="scss" scoped>
    span {
        font-family: $fontText;
    }
    span:first-child {
        font-weight: normal;
        font-size: .7em;
        text-transform: uppercase;
    }
        .icons {
            margin-top: -4px;
            margin-bottom: -4px;
            i {
                font-size: 22px;
                margin-right: 5px;
            }

            span {
                font-size: 14px;
            }
        }
</style>