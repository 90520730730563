<template>
    <Section width="md">
        <HeaderSection
            :header="slice.primary.header"
            :teaser="slice.primary.text"
        />

        <!-- NOTE: Await open positions -->
        <!-- <ul v-if="slice.items.length">
            <li
                v-for="(item, index) in slice.items"
                :key="index"
                class="container-shadow br-3 text-center black flex"
            >
                <div class="padding-8 flex width-full bg-white">
                    <PrismicImage
                        :img="item.image"
                    />
                </div>
                <div class="padding-8 flex width-full bg-lblue">
                    <h3 class="width-full margin-bottom-4">{{ $prismic.asText(item.job_title) }}</h3>
                    <p class="width-full margin-bottom-4">{{ $prismic.asText(item.job_desc) }}</p>

                    <Btn
                        background="pink"
                        link="#"
                        text="Visa jobbet"
                        size="md"
                        icon="fas fa-arrow-right"
                    />
                </div>
            </li>
        </ul> -->
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>
