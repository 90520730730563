<template>
    <div class="latest-posts">
        <h2 class="latest-posts-title">
            {{ title }}
        </h2>
        <nuxt-link 
            v-for="(authorArticle, index) in authorArticles.slice(0, limit)" 
            :key="index"
            :to="$prismic.linkResolver(authorArticle)"
            class="posts-wrapper" 
        >
            <div class="posts-preamble-wrapper">
                <span class="posts-title">{{ $prismic.asText(authorArticle.data.title) }}</span>
                <p class="rich-text posts-preamble">{{ latestPostPreamble(authorArticle) }}</p>
                <span class="posts-date">
                    {{ $translate('published', 'Publicerad') }}
                    {{ $format.date(authorArticle.first_publication_date) }}
                </span>
            </div>
        </nuxt-link>
        <div class="load-posts">
            <Button
                v-if="limit <= authorArticles.length"
                :text="$translate('show_more', 'Visa mer')"
                icon="fas fa-chevron-down"
                background="gray"
                size="sm"
                @click="updateLimit(limit + 15)"
            />
            <Button
                v-if="limit > 5"
                :text="$translate('show_less', 'Visa mindre')"
                icon="fas fa-chevron-up"
                background="gray"
                size="sm"
                @click="updateLimit(5)"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        authorArticles: {
            type: Array,
            required: false,
            default: null
        },
        title: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            limit: 5
        };
    },
    methods: {
        latestPostPreamble(post) {
            return post.data.preamble[0]?.text;
        },
        updateLimit(value) {
            this.limit = value;
        }
    }
};
</script>
<style lang="scss" scoped>
    .latest-posts {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        
        .latest-posts-title {
            margin-bottom: 30px;
            @include device(mobile) {
                margin-bottom: 24px;
            }
        }
        .posts-wrapper {
            text-decoration: none;
            margin-bottom: 1.5rem;
            padding-bottom: 1.5rem;
            border-bottom: 1px solid #e6e6e6;
            &:hover {
                .posts-title {
                    color: $red;
                    text-decoration: none !important;
                }
            }
            .posts-title {
                font-family: $fontHeader;
                color: $pink;
                font-size: 20px;
                font-weight: bold;
                line-height: 1.75rem;
                text-decoration: underline;
            }
            .posts-preamble {
                font-family: $fontText;
                padding-top: 1rem;
                margin-bottom: 0.5rem;
                color: #000;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                -webkit-line-clamp: 2;
                @include device(mobile) {
                    -webkit-line-clamp: 4;
                }
            }
            .posts-date {
                display: inline-block;
                color: $black;
                opacity: 0.5;
                margin-top: 0.5rem;
            }
        }
        .load-posts {
            display: flex;
            justify-content: center;
            column-gap: 1rem;
            button {
                margin-top: 1.5rem;
            }
        }
    }
</style>