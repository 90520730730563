<template>
    <li class="list-item">
        <nuxt-link
            v-if="page.data"
            :to="$prismic.linkResolver(page)"
            class="list-item__link"
        >
            <PrismicImage
                :img="page.data.image"
                class="link-img"
            />

            <div class="link-text">{{ page.data.title }}</div>
        </nuxt-link>
    </li>
</template>
<script>
export default {
    props: {
        page: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
.list-item {
    height: 100%;
    background: $white;
    overflow: hidden;
    box-shadow: $bxs;
    @include border-radius(5px);

    &__link {
        color: $font;
        @include flex;

        &:hover { color: $pink; }

        .link-img {
            @include size(100%, 150px);
            object-fit: cover;
        }

        .link-text {
            font-family: $fontHeader;
            @include font-size(24px);
            @include border(top);
            width: 100%;
            display: block;
            @include spacing(padding,4);
        }
    }
}
</style>
