<template>
    <Section
        width="sm"
        class="image-block"
    >
        <div class="image-block-img">
            <PrismicImage
                :img="slice.primary.image1"
                class="br-5"
            />
        </div>
        <div
            class="image-block-text rich-text"
            v-html="$prismic.asHtml(slice.primary.text)"
        />
    </Section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        },
    },
};
</script>
