<template>
    <Section width="md">
        <!-- TODO Translations or hardcoded? -->
        <div class="container">
            <img
                src="@/assets/icons/pink/icon-contact-us.png"
                alt="contact us"
            >
            <span>Email: {{ slice.primary.contact_email }}</span>
        </div>

        <div class="container">
            <img
                src="@/assets/icons/pink/icon-visit.png"
                alt="visit us"
            >
            <span>{{ slice.primary.street }}</span>
            <span>{{ slice.primary.country }}</span>
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>
<style lang="scss" scoped>
section::v-deep {
    .section-inner {
        @include grid(1, 80px);

        @include device(pad) {
            @include grid(2, 40px);
        }

        .container {
            @include grid(1, 20px);
            text-align: center;

            img {
                @include size(130px);
                margin: auto;
            }

            span {
                display: block;
                @include font-size(26px);
                font-family: $fontHeader;
            }

            &:first-child {
            }
            &:last-child {}
        }
    }
}
</style>
