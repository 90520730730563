<template>
    <nuxt-link 
        :to="$prismic.linkResolver(post)"
        class="container-box" 
    >
        <prismic-image 
            class="post-image"
            :img="post.data.image"
            :alt="$prismic.asText(post.data.title)"
            w="600"
        />
        <div class="container-box-body">
            <h3 class="body-title"> 
                {{ $prismic.asText(post.data.title) }}
            </h3>
            <div>
                <span class="read-more">{{ $translate('cta_read_more', 'Läs mer') }}</span>
                <i class="fas fa-arrow-right" />
            </div>
        </div>
    </nuxt-link>
</template>

<script>
export default {
    props: {
        post: {
            type: Object,
            required: true
        }
    }
};
</script>