<template>
    <tr>
        <th>{{ $translate('ct_mobile_payments_th', 'Mobila betalsätt') }}</th>
        <td v-for="card in allCards" :key="`${card.id}`">
            <ul>
                <li>
                    <i :class="card.model.hasGooglePay ? 'fas fa-check-circle check-mark' : 'fas fa-times-circle cross-mark'" />
                    <span>Google pay</span>
                </li>
                <li>
                    <i :class="card.model.hasSamsungPay ? 'fas fa-check-circle check-mark' : 'fas fa-times-circle cross-mark'" />
                    <span>Samsung pay</span>
                </li>
                <li>
                    <i :class="card.model.hasApplePay ? 'fas fa-check-circle check-mark' : 'fas fa-times-circle cross-mark'" />
                    <span>Apple pay</span>
                </li>
            </ul>    
        </td>
    </tr>
</template>
<script>
export default {
    props: {
        allCards: {
            type: Array,
            required: true
        }
    }
};
</script>