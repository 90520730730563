export default {
    data() {
        const questionsData = {
            benefits: {
                header: this.$translate('guide_question_benefits', 'Vilken förmån är viktigst för dig?'),
                errorText: this.$translate('guide_question_benefits_error', 'Ange vilken förmån som är viktigast'),
                activeValue: '',
                options: [
                    {
                        title: this.$translate('guide_option_petrol_discount', 'Bensinrabatt'),
                        valueKey: 'fuelDiscount',
                        disabled: this.$isGermany(),
                    },
                    {
                        title: this.$translate('guide_option_bonus_program', 'Bonusprogram'),
                        valueKey: 'bonus',
                    },
                    {
                        title: this.$translate('guide_option_cashback', 'Cashback'),
                        valueKey: 'cashback',
                    },
                    {
                        title: this.$translate('guide_option_insurances', 'Försäkringar'),
                        valueKey: 'insurance',
                    },
                    {
                        title: this.$translate('guide_option_credit', 'Kredit'),
                        valueKey: 'credit',
                        disabled: this.$isGermany(),
                    },
                    {
                        title: this.$translate('guide_option_other', 'Annat'),
                        valueKey: 'other',
                    },

                ]
            },
            usage: {
                header: this.$translate('guide_question_usage', 'Vad har du primärt tänkt använda kortet till?'),
                errorText: this.$translate('guide_question_usage_error', 'Ange vad du tänkt använda kortet till'),
                activeValue: '',
                options: [
                    {
                        title: this.$translate('guide_option_travel', 'Resor'),
                        valueKey: 'travel',
                    },
                    {
                        title: this.$translate('guide_option_shopping', 'Shopping'),
                        valueKey: 'shopping',
                    },
                    {
                        title: this.$translate('guide_option_partial_payment', 'Delbetalning'),
                        valueKey: 'partialPayment',
                    },
                    {
                        title: this.$translate('guide_option_buffer', 'Buffert'),
                        valueKey: 'buffer',
                        disabled: this.$isGermany(),
                    },
                    {
                        title: this.$translate('guide_option_other', 'Annat'),
                        valueKey: 'other',
                    },
                ]
            },
            avoidFee: {
                header: this.$translate('guide_question_avoid_fee', 'Vilken utgift vill du helst undvika?'),
                errorText: this.$translate('guide_question_avoid_fee_error', 'Ange vilken utgift du vill undvika'),
                activeValue: '',
                options: [
                    {
                        title: this.$translate('guide_option_yearly_fee', 'Årsavgift'),
                        valueKey: 'yearlyFee',
                    },
                    {
                        title: this.$translate('guide_option_currency_surcharge', 'Valutapåslag'),
                        valueKey: 'exchangeFee',
                    },
                    {
                        title: this.$translate('guide_option_withdrawal_fee', 'Uttagsavgift'),
                        valueKey: 'withdrawalFee',
                    },
                ]
            },
            priority: {
                header: this.$translate('guide_question_priority', 'Vad är viktigast för dig?'),
                errorText: this.$translate('guide_question_priority_error', 'Ange låg kostnad eller förmåner'),
                activeValue: '',
                options: [
                    {
                        title: this.$translate('guide_option_low_cost', 'Låg ränta & avgifter'),
                        valueKey: 'lowCost',
                    },
                    {
                        title: this.$translate('guide_option_benefits', 'Förmåner'),
                        valueKey: 'benefits',
                    },
                ]
            },
            mobilePayments: {
                header: this.$translate('guide_question_mobile_payments', 'Vad för mobilabetalsätt önskar du?'),
                errorText: this.$translate('guide_question_mobile_payments_error', 'Ange vilket mobilabetalsätt du vill ha'),
                activeValue: '',
                options: [
                    {
                        title: this.$translate('guide_option_unimportant', 'Oviktigt/Annat'),
                        valueKey: 'other',
                    },
                    {
                        title: this.$translate('guide_option_apple_pay', 'Apple pay'),
                        valueKey: 'applePay',
                    },
                    {
                        title: this.$translate('guide_option_samsung_pay', 'Samsung pay'),
                        valueKey: 'samsungPay',
                        disabled: this.$isGermany(),
                    },
                    {
                        title: this.$translate('guide_option_google_pay', 'Google pay'),
                        valueKey: 'googlePay',
                    },
                ]
            },
            paymentRemarks: {
                header: this.$translate('guide_question_payment_remarks', 'Har du betalningsanmärkningar?'),
                errorText: this.$translate('guide_question_payment_remarks_error', 'Ange om du har betalningsanmärkninar eller inte'),
                type: 'buttons',
                activeValue: '',
                disabled: this.$isGermany(),
                options: [
                    {
                        title: this.$translate('yes', 'Ja'),
                        valueKey: 'yes',
                    },
                    {
                        title: this.$translate('no', 'Nej'),
                        valueKey: 'no',
                    },
                ]
            },
            schufa: {
                header: this.$translate('guide_question_without_schufa', 'Vill du ha ett kort utan schufa?'),
                errorText: this.$translate('guide_question_without_schufa_error', 'Ange om du vill ha utan schufa'),
                type: 'buttons',
                activeValue: '',
                disabled: this.$isSweden() || this.$isNorway(),
                options: [
                    {
                        title: this.$translate('guide_option_without_schufa', 'Ja utan schufa'),
                        valueKey: 'yes',
                    },
                    {
                        title: this.$translate('guide_option_with_schufa', 'Nej med schufa'),
                        valueKey: 'no',
                    },
                ]
            },
        };
        const activeQuestionsData = Object.fromEntries(
            Object.entries(questionsData)  
                .filter(([, question]) => !question.disabled)
                .map(([key, question]) => {
                    question.options = question.options.filter(option => !option.disabled);
                    return [key, question];
                })
        );
        return {
            questionsData: activeQuestionsData,
            personalInfoData: {
                income: {
                    type: 'number',
                    label: this.$translate('guide_monthly_income_label', 'Månadsinkomst'),
                    formKey: 'monthlyIncome',
                    placeholder: '0',
                    onlyNumbers: true,
                    value: null,
                    validate: () => true             
                },
                birthYear: {
                    type: 'number',
                    label: this.$translate('guide_birth_year_label', 'Födelseår'),
                    formKey: 'birthYear',
                    placeholder: this.$translate('guide_birth_year_placeholder', 'E.g. 1982'),
                    value: null,
                    errorText: this.$translate('guide_birth_year_error', 'Fyll i året du är född'),
                    validate: (input) => {
                        return input?.length === 4;
                    }
                },
                email: {
                    type: 'email',
                    label: this.$translate('guide_email_label', 'Email'),
                    formKey: 'email',
                    placeholder: this.$translate('guide_email_placeholder', 'E.g. john.doe@gmail.com'),
                    value: null,
                    errorText: this.$translate('guide_email_error', 'Fyll i en giltig email'),
                    validate: (input) => {
                        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
                        return input && reg.test(input);
                    }
                },
                phone: {
                    type: 'number',
                    label: this.$translate('guide_phone_label', 'Telefonnummer'),
                    formKey: 'phoneNumber',
                    placeholder: '',
                    value: null,
                    errorText: this.$translate('guide_phone_error', 'Fyll i ett giltigt telefonnummer'),
                    validate: (input) => {

                        const phoneNumber = `${input}`;

                        const reg = new RegExp('^[0-9]+$');
                        if (!reg.test(phoneNumber)) {
                            return false;
                        }
                        if (this.$isNorway()) {
                            return this.validateNorwegianPhoneNumber(phoneNumber);
                        }
                        if (this.$isGermany()) {
                            return this.validateGermanPhoneNumber(phoneNumber);
                        }
                        return this.validateSwedishPhoneNumber(phoneNumber);
                    } 
                },
            },
        };
    },
    methods: {
        validateGermanPhoneNumber(phoneNumber) {
            return (
                (phoneNumber.startsWith('0') && (phoneNumber.length >= 9 && phoneNumber.length <= 12)) ||
                (phoneNumber.startsWith('49') && (phoneNumber.length >= 10 && phoneNumber.length <= 13)) ||
                (phoneNumber.startsWith('+') && (phoneNumber.length >= 11 && phoneNumber.length <= 14)) ||
                (phoneNumber.startsWith('0049') && (phoneNumber.length >= 12 && phoneNumber.length <= 15))
            );
        },
        validateSwedishPhoneNumber(phoneNumber) {
            return (
                (phoneNumber.startsWith('07') && phoneNumber.length === 10) ||
                (phoneNumber.startsWith('46') && phoneNumber.length === 11) ||
                (phoneNumber.startsWith('+') && phoneNumber.length === 12) ||
                (phoneNumber.startsWith('0046') && phoneNumber.length === 13)
            );
        },
        validateNorwegianPhoneNumber(phoneNumber) {
            return (
                (phoneNumber.startsWith('4') && phoneNumber.length === 8) ||
                (phoneNumber.startsWith('9') && phoneNumber.length === 8) ||
                (phoneNumber.startsWith('47') && phoneNumber.length === 10) ||
                (phoneNumber.startsWith('+') && phoneNumber.length === 11) ||
                (phoneNumber.startsWith('0047') && phoneNumber.length === 12) 
            );
        },
    }
};