<template>
    <Section width="md">
        <h2>{{ $prismic.asText(slice.primary.header) }}</h2>

        <div class="container">
            <PrismicImage :img="slice.primary.image" />
        </div>

        <div
            v-animate="{threshold: .5, animation: 'pop'}"
            class="container"
        >
            <p>{{ $prismic.asText(slice.primary.text) }}</p>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        background: $lightpink;
        margin: 40px 0;
        @include device(pad) {
            margin: 48px 0;
        }

        .section-inner {
            @include flex;
            @include border-radius(5px);

            h2 {
                width: 100%;
                text-align: center;
                @include spacing(margin,6,bottom);
            }

            .container {
                @include spacing(padding,5);

                img {
                    @include size(180px);
                }

                &:last-child {
                    flex: 1;
                    max-width: 700px;
                    @include border-radius(5px);
                    box-shadow: $bxs;
                    background: $white;
                }
            }
        }
    }
</style>
