<template>
    <Section
        class="banner"
        width="md"
    >
        <HeaderBanner
            :header="$prismic.asText(slice.primary.h1)"
            :teaser="slice.primary.text"
            align="center"
        />

        <div class="container">
            <div
                v-for="(cat, index) in slice.items"
                :key="index"
                v-scroll-to="'#' + cat.category_id"
                class="container-inner"
            >
                <PrismicImage
                    :img="cat.image"
                    :alt="$prismic.asText(cat.category_text)"
                    w="100"
                    h="100"
                    class="container-inner__img"
                />
                <span class="container-inner__text">{{ $prismic.asText(cat.category_text) }}</span>
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .banner {
        .section-inner {

            .container {
                @include flex;
                @include spacing(margin,8,top);

                &-inner {
                    width: 100%;
                    margin-bottom: 10px;
                    text-align: center;
                    box-shadow: $bxs;
                    @include border-radius(5px);
                    @include spacing(padding,6);
                    font-family: $fontHeader;
                    @include font-size(24px);
                    cursor: pointer;
                    @include flex(start);

                    @include device(pad) {
                        margin: 10px;
                        max-width: 220px;
                        @include grid(1, 20px);
                        flex: 1;
                    }

                    &:hover { color: $pink; }

                    &__img {
                        @include size(40px);
                        margin-right: 20px;

                        @include device(pad) {
                            margin: auto;
                            @include size(80px);
                        }
                    }
                }
            }
        }
    }
</style>
