<template>
    <Section
        class="banner"
        width="lg"
    >
        <div class="container">
            <div class="container-inner">
                <HeaderBanner
                    :header="$prismic.asText(slice.primary.h1)"
                    :teaser="slice.primary.text"
                    align="left"
                />

                <ButtonNuxt
                    :link="slice.primary.link"
                    :text="$prismic.asText(slice.primary.link_text)"
                    background="pink"
                    size="lg"
                />
            </div>
        </div>

        <div class="container">
            <PrismicImage
                :img="slice.primary.image"
                w="520"
                h="388"
                :lazy="false"
            />
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>
<style lang="scss" scoped>
.banner::v-deep {
    overflow-x: hidden;

    .section-inner {
        @include grid(1, 80px);

        @include device(pad) {
            @include grid(2, 0);
        }
        @include device(desktop) {
            @include grid(2, 80px);
        }

        .container {
            &:first-child {
                position: relative;
                z-index: 2;
                @include flex(start);

                .container-inner {
                    @include flex(start);

                    h1 {
                        @include device(mobile) {
                            max-width: 50%;
                        }
                    }

                    .btn { @include spacing(margin,8,top); }
                }
            }

            &:last-child {
                position: absolute;
                right: 0;

                @include device(pad) {
                    position: relative;
                }

                img {
                    @include size(200px, auto);

                    @include device(pad) {
                        @include size(100%, auto);
                        max-width: 600px;
                    }

                    @include device(desktop) {
                        max-width: none;
                    }
                }
            }
        }
    }
}
</style>
