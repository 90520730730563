<template>
    <Section width="sm">
        <div class="container">
            <div class="container-inner">
                <prismic-image
                    v-if="author.data.image"
                    :img="author.data.image"
                    h="70"
                    w="70"
                />

                <div class="author-publication-header">
                    <nuxt-link :to="$prismic.linkResolver(author)" class="author-name">
                        {{ author.data.name }}
                    </nuxt-link>
                    <a 
                        v-if="author.data.email" 
                        :href="`mailto:${author.data.email}`"
                        class="author-publication-email"
                    >
                        <i class="fas fa-envelope" />
                        <span>{{ author.data.email }}</span>
                    </a>
                </div>
            </div>


            <p 
                class="rich-text"
                v-html="$removeParagraphs($prismic.asHtml(author.data.bio))"
            />

            <span class="author-publication-date">
                {{ publicationDate }}
            </span>

            <div v-if="sources.length" class="sources-container">
                <span>{{ $translate('authority_box_sources', 'Källor') }}</span>
                <ul>
                    <li v-for="(source, index) in sources" :key="index">
                        <a :href="source.url" target="_blank">
                            {{ source.url }}
                        </a>
                    </li>
                </ul>
            </div>

            <div v-if="editor && editor.data" class="author-publication-container">
                <div class="author-publication-body">
                    <prismic-image
                        :img="editor.data.image"
                        class="author-publication-image"
                        h="30"
                        w="30"
                    />
                    <nuxt-link :to="$prismic.linkResolver(editor)" class="author-publication-name">
                        <p>
                            {{ $translate('authority_box_reviewed_by', 'granskad av') }}
                            <span>{{ editor.data.name }}</span>
                        </p>
                    </nuxt-link>
                </div>
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            publishedDate: this.$format.date(this.document.first_publication_date, 'DATE_MED'),
            lastUpdatedDate: this.$format.date(this.document.last_publication_date, 'DATE_MED')
        };
    },
    computed: {
        publicationDate() {
            if (this.publishedDate === this.lastUpdatedDate) {
                return this.$translate('authority_box_first_publication_date', 'Publicerad {date}')
                    .replace('{date}', this.publishedDate);
            }
            return this.$translate('authority_box_last_publication_date', 'Senast uppdaterad {date}')
                .replace('{date}', this.lastUpdatedDate);
        },
        author() {
            return this.document.data.author;
        },
        editor() {
            return this.document.data.editor;
        },
        sources() {
            return this.document.data.sources.filter(source => source.url);
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    .section-inner {
        .container-inner {
            @include flex;
            padding-bottom: 20px;
            margin-bottom: 20px;
            @include border(bottom);
            gap: 1rem;
            justify-content: flex-start;
            img { @include border-radius(100px); }
            .author-publication-header {
                display: flex;
                flex-flow: wrap column;
                gap: 0.5rem;
                .author-name {
                    flex: 1;
                    color: $black;
                    font-family: $fontHeader;
                    @include font-size(24px);
                    &:hover {
                        color: $pink;
                        text-decoration: underline;
                    }
                }
                .author-publication-email {
                    span {
                        font-family: $fontHeader;
                        @include font-size(16px);
                        color: #677788;
                        
                        &:hover { color: $pink; }
                    }


                    i {
                        color: $pink;
                        margin-right: 5px;
                    }
                }
            }
        
        }
        .author-publication-date {
            font-family: $fontText;
            @include font-size(14px);
            opacity: .5;
            font-weight: lighter;
            margin-top: 20px;
            display: inline-block;
        }
        .author-publication-container {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            margin-top: 20px;
            border-top: .0625rem solid rgba(33, 50, 91, .1);
            
            .author-publication-body {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                margin-top: 20px;

                .author-publication-image {
                    border-radius: 50%;
                }
                
                .author-publication-name {
                    color: inherit;
                    p {
                        font-family: $fontText;
                        margin: 0;
                    }
                    p::first-letter {
                        text-transform: capitalize;
                    }
                    p span {
                        font-weight: bold;
                        &:hover {
                            color: $pink;
                            text-decoration: underline;
                        }
                    }
                    p:hover {
                        span {
                            color: $pink;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .sources-container {
            @include spacing(margin, 4, top);
            span {
                @include font-size(24px);
                font-family: $fontHeader;
            }
            ul {
                @include spacing(margin, 4, top);
                @include grid(1, 5px);
                row-gap: 1rem;
                
                li a {
                    font-family: $fontText;
                    @include font-size(14px);
                    color: $pink;
                    @include flex(start);
                    word-break: break-all;

                    &:hover { text-decoration: underline; }

                    img {
                        @include size(30px, auto);
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
</style>
