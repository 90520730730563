<template>
    <Section
        :label="slice.slice_label"
        width="md"
        :class="{ banner: isFirst }"
    >
        <h1 v-if="isFirst && title.length > 0">{{ title }}</h1>
        <h2 v-else-if="title.length > 0">{{ title }}</h2>
        <p v-if="info.length > 0">{{ info }}</p>

        <ul>
            <CreditCard
                v-for="(card, index) in cardsToShow"
                :id="card.id"
                :key="card.id"
                placement="list"
                :position="index + 1"
                :slice="slice"
            />
        </ul>
        <trust-us v-if="!slice.primary.disable_trust_us" />
    </Section>
</template>

<script>
import CreditCard from '@/components/cards/CreditCard.vue';
import TrustUs from '@/components/TrustUs.vue';
export default {
    components: { CreditCard, TrustUs },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        },
        isFirst: {
            type: Boolean,
            required: true
        }
    },
    data: function() {
        return {
            hasList: typeof this.slice.primary.predefined_list.id !== 'undefined'
        };
    },
    computed: {
        cards(){
            if (this.hasList) {
                if (this.$store.state.lists[this.slice.primary.predefined_list.id]) {
                    return this.$store.state.lists[this.slice.primary.predefined_list.id].data.cards
                        .map(iterator => iterator.card);
                }
                return [];
            }
            return this.slice.items
                .filter(this.$filterEmptyRelations)
                .map(iterator => iterator.ccl_card);
        },
        cardsToShow() {
            return this.cards
                .filter(card => this.$store.state.cards[card.id] && !this.$store.state.cards[card.id].data.lists_disabled);
        },
        title() {
            return this.$prismic.asText(this.slice.primary.list_title);
        },
        info() {
            return this.$prismic.asText(this.slice.primary.list_info);
        }
    }
};
</script>
<style lang="scss" scoped>
    ul {
        width: 100%;
        @include grid(1, 40px);
    }

    h1,
    h2,
    p {
        width: 100%;
        text-align: left;
        @include spacing(margin, 6, bottom);
    }
</style>
