<template>
    <Section
        :label="slice.slice_label"
        width="md"
        :class="{ banner: isFirst }"
    >
        <h2 v-if="$prismic.asText(slice.primary.list_title).length > 0">{{ $prismic.asText(slice.primary.list_title) }}</h2>
        <p v-if="$prismic.asText(slice.primary.list_info).length > 0">{{ $prismic.asText(slice.primary.list_info) }}</p>
        <ul>
            <lender 
                v-for="(lender, index) in lenders"
                :key="index"
                :lender="lender" 
                :template="template"
                placement="list"
                :position="index + 1"
            />
        </ul>
        <trust-us v-if="!slice.primary.disable_trust_us" />
    </Section>
</template>
<script>
import Lender from '@/components/cards/Lender.vue';
import TrustUs from '@/components/TrustUs.vue';
export default {
    components: {
        Lender,
        TrustUs
    },
    props: {
        slice: {
            type: Object,
            required: true,
        },
        isFirst: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        lenders() {
            return this.slice.items
                .map(item => item.lender)
                .filter(lender => lender.id);
        },
        template() {
            if (this.slice.primary.template) {
                return this.slice.primary.template;
            }

            return 'credit_account';
        }
    }
};
</script>
<style lang="scss" scoped>
    ul {
        width: 100%;
        @include grid(1, 40px);
    }

    h2,
    p {
        width: 100%;
        text-align: left;
        @include spacing(margin, 6, bottom);
    }
</style>