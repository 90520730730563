<template>
    <Section
        width="sm"
        :label="slice.slice_label"
    >
        <h2 class="header">{{ $prismic.asText(slice.primary.header) }}</h2>

        <div class="grid" itemscope itemtype="https://schema.org/FAQPage">
            <QuestionAndAnswer
                v-for="(iterator, index) in slice.items"
                :key="index"
                :question="$prismic.asText(iterator.question)"
                :answer="$prismic.asHtml(iterator.answer)"
            />
        </div>
    </Section>
</template>

<script>
import { QuestionAndAnswer } from '@swegaming-ab/vue-components';
export default {
    components: { QuestionAndAnswer },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/css/slices/_richtext.scss';
section::v-deep {
    .grid {
        @include grid(1, 15px);

        .question-answer {
            background: $white;
            @include border-radius(5px);
            box-shadow: $bxs;
            color: $black;
            @include border-radius(3px);

            .question {
                @include spacing(padding, 5);
                font-family: $fontHeader;

                &-text {
                    font-family: $fontHeader;
                    color: $font;
                    @include font-size(20px);
                    font-weight: bold;
                    padding-right: 10px;
                }
            }

            .answer {
                @extend .rich-text;
                @include spacing(padding, 5);
                @include border(top, 1px, $black);

                p:first-child { margin-top: 0; }
            }
        }
    }
}
</style>
