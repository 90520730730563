<template>
    <ul>
        <template v-if="template === 'refinancing' && $isSweden()">
            <li>
                <span>Lånebelopp</span>
                <span v-html="model.amountString" />
            </li>
            <li>
                <span>Löptid</span>
                <span>{{ model.getDurationString() }}</span>
            </li>
            <li>
                <span>{{ $translate('effective_rate', 'Effektiv ränta') }}</span>
                <span>{{ model.effectiveInterestString }}</span>
            </li>
            <li>
                <span>Kreditupplysning</span>
                <span>{{ creditCheck }}</span>
            </li>
            <li>
                <span>Accepterar anmärkning</span>
                <span><i :class="(model.acceptsPaymentRemarks) ? 'fas fa-check-circle check-mark' : 'fas fa-times-circle cross-mark'" /></span>
            </li>
            <li v-if="model.isBroker">
                <span>Anslutna banker</span>
                <span>{{ model.connectedBanks }}</span>
            </li>
            <li v-else>
                <span>Uppläggningsavgift</span>
                <span>{{ model.setupFeeString }}</span>
            </li>
        </template>
        <template v-else>
            <li>
                <span>{{ $translate('max_credit', 'Maxkredit') }}</span>
                <span v-html="model.maxAmountString" />
            </li>
            <li>
                <span>{{ $translate('yearly_fee', 'Årskostnad') }}</span>
                <span v-html="$format.currency(0)" />
            </li>
            <li>
                <span>{{ $translate('effective_rate', 'Effektiv ränta') }}</span>
                <span>{{ model.effectiveInterestString }}</span>
            </li>
            <li v-if="$isSweden()">
                <span>Utan UC</span>
                <span><i :class="(! model.takesUC) ? 'fas fa-check-circle check-mark' : 'fas fa-times-circle cross-mark'" /></span>
            </li>
            <li v-if="$isSweden()">
                <span>Accepterar anmärkning</span>
                <span><i :class="(model.acceptsPaymentRemarks) ? 'fas fa-check-circle check-mark' : 'fas fa-times-circle cross-mark'" /></span>
            </li>
            <li v-if="$isSweden()">
                <span>Kreditupplysning</span>
                <span>{{ creditCheck }}</span>
            </li>
        </template>
    </ul>
</template>
<script>
import { capitalize } from 'lodash';
export default {
    props: {
        model: {
            type: Object,
            required: true,
        },
        template: {
            type: String,
            required: false,
            default: 'credit_account'
        }
    },
    computed: {
        creditCheck() {
            return capitalize(this.model.creditCheck);
        }
    }
};
</script>

<style lang="scss" scoped>
    ul {
        @include grid(1, 0px);
        @include font-size(14px, 0px);

        @include device(pad) {
            @include grid(3, 0px);
        }

        li {
            @include grid(2, 10px);
            @include spacing(padding, 3);
            text-align: left;
            background: $white;
            margin-bottom: 3px;

            @include device(pad) {
                @include spacing(padding, 2);
                @include grid(1, 10px);
                margin-right: 3px;

                &:nth-child(3n) {
                    margin-right: 0;
                }

            }

            span {
                width: 100%;
                font-family: $fontText;
            }
            span:last-child {
                text-align: right;
                font-weight: bold;

                @include device(pad) { text-align: left; }

                &.icons {
                    margin-top: -4px;
                    margin-bottom: -4px;
                    i {
                        font-size: 22px;
                        margin-right: 5px;
                    }

                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .fa-check-circle { color: $green; }
    .fa-times-circle { color: $red; }
</style>