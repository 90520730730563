<template>
    <button
        role="button" 
        class="btn question-option-button" 
        :class="{'active': active === valueKey }" 
        @click="setActive()"
    >
        <span>{{ title }}</span>
    </button>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        valueKey: {
            type: String,
            required: true
        },
        active: {
            type: String,
            required: true
        }
    },
    methods: {
        setActive() {
            this.$emit('setActive', this.valueKey); 
        }
    }
};
</script>