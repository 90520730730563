<template>
    <Section id="guide-section" :width="showResults ? 'md' : 'standard'" class="guide-section">
        <div v-if="! showResults" id="guide" class="guide-container">
            <container 
                v-for="(question, questionKey, index) in questionsData"
                :id="`question${index}`"
                :key="index"
                :header="question.header"
                :active-value="question.activeValue"
                :error-text="question.errorText"
                :show-errors="showErrors"
            >
                <div 
                    class="question-buttons" 
                    :class="{'many-buttons': question.options.length > 2 }"
                >
                    <option-button 
                        v-for="(option, optionIndex) in question.options"
                        :key="optionIndex"
                        :title="option.title"
                        :value-key="option.valueKey"
                        :active="question.activeValue"
                        @setActive="(value) => { setActive(value, questionsData[questionKey]), scrollToNext(index) }"
                    />
                </div>
            </container>
            <container :id="`question${Object.keys(questionsData).length}`" :header="$translate('guide_form_header', 'Fyll i dina personsuppgifter')">
                <form class="personal-info-form">
                    <dynamic-input
                        v-for="(question, index) in personalInfoData"
                        :key="index"
                        class="personal-info-form-question"
                        :form-key="question.formKey"
                        :type="question.type"
                        :label="question.label"
                        :value="question.value"
                        :placeholder="question.placeholder"
                        :error-text="question.errorText"
                        :error="showErrors && ! question.validate(question.value)"
                        @set="(_, input) => question.value = input"
                    />
                    <Button
                        :text="$translate('guide_button_show_results', 'Visa kreditkorten som passar för mig')"
                        size="sm"
                        background="green"
                        @click="submit()"
                    />
                    <div 
                        v-if="$validateText($translate('guide_terms'))"
                        class="terms rich-text"
                        v-html="$prismic.asHtml($translate('guide_terms'))"
                    />
                </form>
            </container>

            <progressbar element-id="guide" screen-size-offset="middle" />
        </div>
        <guide-result 
            v-else
            :has-payment-remarks="hasPaymentRemarks"
            :payment-remarks-lenders="paymentRemarksLenders"
            :payment-remarks-cards="paymentRemarksCards"
            :result-list="filteredList()"
            @redoGuide="redoGuide()"
        /> 
    </Section>
</template>

<script>

import Container from '@/components/card-guide/QuestionContainer.vue';
import OptionButton from '@/components/card-guide/QuestionOptionButton.vue';
import DynamicInput from '@/components/DynamicInput.vue';
import Progressbar from '@/components/Progressbar.vue';
import GuideResult from '@/components/card-guide/GuideResult.vue';

import QuestionsData from '@/components/card-guide/QuestionsData.js';
import ScoreLogic from '@/components/card-guide/ScoreLogic.js';
export default {
    components: {
        Container,
        OptionButton,
        DynamicInput,
        Progressbar,
        GuideResult,
    },
    mixins: [QuestionsData, ScoreLogic],
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showErrors: false,
            showResults: false,
        };
    },
    computed: {
        paymentRemarksCards() {
            if ((! this.showResults) || (! this.hasPaymentRemarks)) {
                return [];
            }
            return this.getPaymentRemarksCards();
        },
        paymentRemarksLenders() {
            if ((! this.showResults) || (! this.hasPaymentRemarks)) {
                return [];
            }

            return this.getPaymentRemarksLenders();
        },
    },
    methods: {
        setActive(value, data) {
            data.activeValue = value;
        },
        scrollToNext(currentQuestionIndex) {
            if (this.showErrors) {
                return this.scrollToError();
            }
            this.$scrollTo(`#question${currentQuestionIndex + 1}`, 500, {offset: -100});
        },
        submit() {
            if (this.validateAnswers()) {
                this.setResults();
                this.showResults = true;
                this.showErrors = false;
                this.$nextTick(() => this.$scrollTo('#guide-section', 500, {offset: -50}));
                this.sendTrackingData();
            } 
            else {
                this.showErrors = true;
                this.scrollToError();
            }
        },
        validateAnswers() {
            const questionsValidated = ! Object.values(this.questionsData).some(question => {
                return question.activeValue === '';
            });

            const formValidated = ! Object.values(this.personalInfoData).some(input => {
                return ! input.validate(input.value);
            });
            return questionsValidated && formValidated;
        },
        scrollToError() {
            const errorIndex = Object.values(this.questionsData).findIndex(question => {
                return question.activeValue === '';
            });
            if (errorIndex >= 0) {
                return this.$nextTick(() => this.$scrollTo(`#question${errorIndex}`, 500, {offset: -100}));
            }
            return this.$nextTick(() => this.$scrollTo(`#question${Object.keys(this.questionsData).length}`, 500, {offset: -100}));
        },
        formatPhoneNumber(phoneNumber) {
            if (this.$isNorway()) {
                if (phoneNumber.startsWith('47')) {
                    return phoneNumber;
                }
                if (phoneNumber.startsWith('0047')) {
                    return phoneNumber.replace('0047', '47');
                }
                if (phoneNumber.startsWith('+47')) {
                    return phoneNumber.replace('+47', '47');
                }
                return `47${phoneNumber}`;
            }
            if (this.$isGermany()) {
                if (phoneNumber.startsWith('49')) {
                    return phoneNumber;
                }
                if (phoneNumber.startsWith('0049')) {
                    return phoneNumber.replace('0049', '49');
                }
                if (phoneNumber.startsWith('+49')) {
                    return phoneNumber.replace('+49', '49');
                }
                if (phoneNumber.startsWith('0')) {
                    return phoneNumber.replace('0', '49');
                }
                return `49${phoneNumber}`;
            }
            return phoneNumber;
        },
        sendTrackingData() {
            const payload = {
                // eslint-disable-next-line camelcase
                guide_id: this.$store.state.settings.broker_source_id,
                user: {
                    email: this.personalInfoData.email.value,
                    // eslint-disable-next-line camelcase
                    phone_number: this.formatPhoneNumber(this.personalInfoData.phone.value),
                },
                questionnaire: {
                    // eslint-disable-next-line camelcase
                    monthly_income: Number(this.personalInfoData.income.value),
                }
            };

            if (this.$isSweden() || this.$isNorway()) {
                // eslint-disable-next-line camelcase
                payload.questionnaire.payment_remarks = this.questionsData.paymentRemarks.activeValue === 'yes';
            }

            payload.tracking = this.$tracking.getTrackingObject();
            this.$axios.post('https://api.broker.compary.com/guides', payload)
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        },
        redoGuide() {
            this.resetScore();
            this.showResults = false;
            Object.values(this.questionsData).forEach(question => {
                question.activeValue = '';
            });
            this.$nextTick(() => this.$scrollTo('#question0', 500, {offset: -100}));

        }   
    }
};
</script>
