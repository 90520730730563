<template>
    <div class="wrapper-post">
        <post-banner :document="document" /> 

        <Slices :slices="document.data.body" />

        <Author
            v-if="document.data.author && document.data.author.data.image"
            :document="document"
        />
        <script type="application/ld+json" v-html="jsonld" />
    </div>
</template>

<script>
import Author from '@/components/Author.vue';
import Slices from '@/components/slices/Slices.vue';
import PostBanner from '@/components/PostBanner.vue';
export default {
    components: { 
        Slices, 
        Author,
        PostBanner
    },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        },
        url: {
            type: String,
            required: false,
            default: String
        }
    },
    data() {

        let jsonld = {
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            'mainEntityOfPage': {
                '@type': 'WebPage',
                'url': this.url
            },
            'headline': this.document.data.title,
            'dateModified': this.document.last_publication_date.split('T')[0],
        };

        if (this.document.data.author.data) {
            jsonld.author = {
                '@type': 'Person',
                'name': this.document.data.author.data.name
            };
        }

        if (this.document.data.image && this.document.data.image.url) {
            jsonld.image = this.document.data.image.url;
        }

        return {
            jsonld
        };
    }
};
</script>

<style lang="scss" scoped>
    .wrapper-post::v-deep {
        section.post-card-info { padding-top: 0; }
    }
</style>