<template>
    <td>
        <div class="table-data-header">{{ dataHeader }}</div>
        <div v-html="data" />
    </td>
</template>

<script>
export default {
    props: {
        dataHeader: {
            type: String,
            required: true
        },
        data: {
            type: String,
            required: true
        }
    }
};
</script>
