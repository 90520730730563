export default {
    computed: {
        insurances () {
            return [
                {   
                    exist: this.checkInsurance('hasPurchaseProtection'),
                    title: this.$translate('ct_purchase_protection', 'Köpskydd')
                },
                {
                    exist: this.checkInsurance('hasDeliveryInsurance'),
                    title: this.$translate('ct_delivery_insurance', 'Leveransförsäkring')
                },
                {
                    exist: this.checkInsurance('hasTravelInsurance'),
                    title: this.$translate('ct_travel_insurance', 'Reseförsäkring')
                },
                {
                    exist: this.checkInsurance('hasPriceGuarantee'),
                    title: this.$translate('ct_price_guarantee', 'Prisgaranti')
                },
                {
                    exist: this.checkInsurance('hasComprehensiveInsurance'),
                    title: this.$translate('ct_comprehensive_insurance', 'Allriskförsäkring')
                },
                {
                    exist: this.checkInsurance('hasCancellationProtection'),
                    title: this.$translate('ct_cancellation_protection', 'Avbeställningsskydd')
                },
                {
                    exist: this.checkInsurance('hasDeductibleInsurance'),
                    title: this.$translate('ct_deductible_insurance', 'Självriskreducering')
                },
                {
                    exist: this.checkInsurance('hasLostKeyInsurance'),
                    title: this.$translate('ct_lost_key_insurance', 'Nyckelförsäkring')
                },
                {
                    exist: this.checkInsurance('hasWrongFuelInsurance'),
                    title: this.$translate('ct_wrong_fuel_insurance', 'Feltankningsförsäkring')
                },
                {
                    exist: this.checkInsurance('hasIdTheftInsurance'),
                    title: this.$translate('ct_id_theft_insurance', 'Id-skyddsförsäkring')
                },
                {   
                    exist: this.checkInsurance('hasCancelledEventInsurance'),
                    title: this.$translate('ct_cancelled_event_insurance', 'Evenemangsförsäkring') 
                },
                {   
                    exist: this.checkInsurance('hasHoleInOneInsurance'),
                    title: this.$translate('ct_hole_in_one_insurance', 'Hole-in-one försäkring') 

                },
            ];
        },
        insurancesToShow () {
            return this.insurances.filter(insurance => insurance.exist.includes(true));
        },
    },
    methods: {
        checkInsurance (insurance) {
            return this.allCards.map(card => {
                return card.model[insurance];
            });
        },
    }
};