
<!-- TODO: Translations -->
<template>
    <Section
        :label="slice.slice_label"
        width="md"
        :class="{ banner: isFirst }"
    >
        <h1 v-if="isFirst && title.length > 0">{{ title }}</h1>
        <h2 v-else-if="title.length > 0">{{ title }}</h2>
        <p v-if="info.length > 0">{{ info }}</p>

        <div class="filter-container">
            <div class="toggle">
                <div class="toggles">
                    <a 
                        v-for="button in filterButtons"
                        :key="button.key"
                        class="btn btn-sm btn-filter" 
                        @click="toggle(button.key);"
                    >
                        {{ button.title }} 
                        <span v-if="countFilters(button.key) > 0" class="count">{{ countFilters(button.key) }}</span> 
                        <i class="fas" :class="filter[button.key].show ? 'fa-chevron-up' : 'fa-chevron-down'" />
                    </a>
                </div>
                <span class="cards-counter">
                    {{ 
                        $translate('filter_result_count', 'Visar {count} av {total} kreditkort')
                            .replace('{count}', filteredCards.length)
                            .replace('{total}', totalCardCount)
                    }}
                </span> 
            </div>
            <template
                v-for="(category, index) in filter"
            >
                <div v-show="category.show" :key="index" class="filter">
                    <div 
                        v-for="(checkbox, jindex) in category.data"
                        :key="jindex"
                        class="checkbox-and-tooltip"
                    >
                        <label class="checkbox">   
                            {{ checkbox.title }}
                            <input v-model="checkbox.active" type="checkbox">
                            <span class="checkmark" />
                        </label>
                        <tooltip 
                            v-if="checkbox.tooltip" 
                            :tooltip-text="checkbox.tooltip"
                        />
                    </div>
                </div>
            </template>
        </div>
        <ul v-show="list.length > 0">
            <CreditCard
                v-for="(card, index) in list"
                :id="card.id"
                :key="index"
                placement="list"
                :position="index + 1"
            />
        </ul>
        <div v-if="list.length === 0" class="error">
            <i class="fas fa-times-circle" /> {{ $translate('filter_no_results', 'Vi hittade inga kreditkort med din sökning.') }}
        </div>
        <trust-us v-if="!slice.primary.disable_trust_us" />
    </Section>
</template>

<script>
import CreditCard from '@/components/cards/CreditCard.vue';
import Tooltip from '@/components/Tooltip.vue';
import TrustUs from '@/components/TrustUs.vue';
export default {
    components: { 
        CreditCard,
        Tooltip,
        TrustUs
    },
    props: {
        slice: {
            type: Object,
            required: true,
        },
        isFirst: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            show: {
                bonus: false,
                costs: false,
                recommended: false,
                travel: false,
            },

            allfilters: {
                bonus: {
                    show: false,
                    title: this.$translate('filter_bonus_benefits_header', 'Bonus & förmåner'),
                    data: {
                        bonus: {
                            title: this.$translate('filter_bonus_program', 'Bonusprogram'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_bonus_program'),
                            filterOutFunction: (card, model) => ! model.hasBonusProgram
                        },
                        cashback: {
                            title: this.$translate('filter_cashback', 'Cashback'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_cashback'),
                            filterOutFunction: (card, model) => ! model.hasCashback
                        },
                        fuelOffers: {
                            title: this.$translate('filter_fuel_offers', 'Drivmedelsrabatter'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_fuel_offers'),
                            filterOutFunction: (card, model) => ! model.hasFuelOffers
                        },
                        // TODO: add to Core data
                        // other: {
                        //     title: this.$translate('filter_other_rebates', 'Andra rabatter'),
                        //     active: false,
                        //     filterOutFunction: (card, model) => false
                        // },
                        lounge: {
                            title: this.$translate('filter_lounge_access', 'Loungetillgång'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_lounge_access'),
                            filterOutFunction: (card, model) => ! model.hasLoungeAccess
                        },
                        welcomeOffer: {
                            title: this.$translate('filter_welcome_offer', 'Välkomsterbjudande'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_welcome_offer'),
                            filterOutFunction: (card, model) => ! model.hasWelcomeOffer
                        },
                        hotelDiscount: {
                            title: this.$translate('filter_hotel_discount', 'Hotellrabatt'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_hotel_discount'),
                            filterOutFunction: (card, model) => ! model.hasHotelDiscounts
                        },
                        travelDiscount: {
                            title: this.$translate('filter_travel_discount', 'Reserabatt'),
                            disabled: !this.$isGermany(),
                            tooltip: this.$translate('tooltip_filter_travel_discount'),
                            active: false,
                            filterOutFunction: (card, model) => ! model.hasTravelDiscounts
                        },
                        rentalCarDiscount: {
                            title: this.$translate('filter_rental_car_discount', 'Hyrbilsrabatt'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_rental_car_discount'),
                            filterOutFunction: (card, model) => ! model.hasRentalCarDiscounts
                        },
                        // TODO: add to Core data
                        // fastTrack: {
                        //     title: this.$translate('filter_fast_track', 'Fast track'),
                        //     active: false,
                        //     filterOutFunction: (card, model) => false
                        // }
                    }
                },
                fees: {
                    show: false,
                    title: this.$translate('filter_fees_credit_header', 'Avgifter & kredit'),
                    data: {
                        free: {
                            title: this.$translate('filter_no_yearly_cost', 'Utan årsavgift'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_no_yearly_cost'),
                            filterOutFunction: (card, model) => ! (model.yearlyCost === 0 || (model.hasFirstYearOffer && model.firstYearCost === 0))
                        },
                        withdrawal: {
                            title: this.$translate('filter_no_withdrawal_fees', 'Utan uttagsavgift'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_no_withdrawal_fees'),
                            filterOutFunction: (card, model) => model.withdrawalFeeType !== 'free'
                        },
                        currency: {
                            title: this.$translate('fitler_no_currency_surcharge', 'Utan valutapåslag'),
                            active: false,
                            tooltip: this.$translate('tooltip_fitler_no_currency_surcharge'),
                            filterOutFunction: (card, model) => model.currencyExchangeFeeType !== 'free'
                        },
                        lowInterests: {
                            title: this.$translate('filter_low_effective_interest', 'Låg effektiv ränta (under 15%)'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_low_effective_interest'),
                            filterOutFunction: (card, model) => this.$isNorway() ? model.minEffectiveInterestPossible > 25 : model.minEffectiveInterestPossible > 15
                        },
                        manyInterestFreeDays: {
                            title: this.$translate('filter_many_interest_free_days', 'Lång räntefri betalningsperiod (+45 dagar)'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_many_interest_free_days'),
                            filterOutFunction: (card, model) => model.interestFreeDays < 45
                        },
                        highMaxCredit: {
                            title: this.$translate('filter_high_max_credit', 'Hög maxkredit (+100 000 kr)'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_high_max_credit'),
                            filterOutFunction: (card, model) => !(model.maxCredit >= 100000 || model.hasUnlimitedCredit)
                        },
                        isCreditCard: {
                            title: this.$translate('filter_only_credit_cards', 'Endast kreditkort'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_only_credit_cards'),
                            filterOutFunction: (card, model) => ! model.isCredit
                        },
                        isDebitCard: {
                            title: this.$translate('filter_only_debit_cards', 'Endast debetkort'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_only_debit_cards'),
                            filterOutFunction: (card, model) => ! model.isDebit
                        },
                    }
                },
                insurances: {
                    show: false,
                    title: this.$translate('filter_insurances_header', 'Försäkringar'),
                    data: {
                        travel: {
                            title: this.$translate('ct_travel_insurance', 'Reseförsäkring'),
                            active: false,
                            tooltip: this.$translate('tooltip_ct_travel_insurance'),
                            filterOutFunction: (card, model) => ! model.hasTravelInsurance
                        },
                        priceGuarantee: {
                            title: this.$translate('ct_price_guarantee', 'Prisgaranti'),
                            active: false,
                            tooltip: this.$translate('tooltip_ct_price_guarantee'),
                            filterOutFunction: (card, model) => ! model.hasPriceGuarantee
                        },
                        cancellationProtection: {
                            title: this.$translate('ct_cancellation_protection', 'Avbeställningsskydd'),
                            active: false,
                            tooltip: this.$translate('tooltip_ct_cancellation_protection'),
                            filterOutFunction: (card, model) => ! model.hasCancellationProtection
                        },
                        deductibleInsurance: {
                            title: this.$translate('ct_deductible_insurance', 'Självriskförsäkring'),
                            active: false,
                            tooltip: this.$translate('tooltip_ct_deductible_insurance'),
                            filterOutFunction: (card, model) => ! model.hasDeductibleInsurance
                        },
                        comprehensiveInsurance: {
                            title: this.$translate('ct_comprehensive_insurance', 'Allriskförsäkring'),
                            active: false,
                            tooltip: this.$translate('tooltip_ct_comprehensive_insurance'),
                            disabled: this.$isNorway(),
                            filterOutFunction: (card, model) => ! model.hasComprehensiveInsurance
                        },
                        purchaseProtection: {
                            title: this.$translate('ct_purchase_protection', 'Betalskydd'),
                            active: false,
                            tooltip: this.$translate('tooltip_ct_purchase_protection'),
                            filterOutFunction: (card, model) => ! model.hasPurchaseProtection
                        },
                        deliveryInsurance: {
                            title: this.$translate('ct_delivery_insurance', 'Leveransförsäkring'),
                            active: false,
                            tooltip: this.$translate('tooltip_ct_delivery_insurance'),
                            disabled: this.$isNorway(),
                            filterOutFunction: (card, model) => ! model.hasDeliveryInsurance
                        },
                        extendedWarranty: {
                            title: this.$translate('ct_extended_warranty', 'Förlängd garanti'),
                            active: false,
                            tooltip: this.$translate('tooltip_ct_extended_warranty'),
                            filterOutFunction: (card, model) => ! model.hasExtendedWarranty
                        },
                        holeInOneInsurance: {
                            title: this.$translate('ct_hole_in_one_insurance', 'Hole-in-one försäkring'),
                            active: false,
                            tooltip: this.$translate('tooltip_ct_hole_in_one_insurance'),
                            disabled: this.$isGermany(),
                            filterOutFunction: (card, model) => ! model.hasHoleInOneInsurance
                        },
                        lostKeyInsurance: {
                            title: this.$translate('ct_lost_key_insurance', 'Nyckelförsäkring'),
                            active: false,
                            tooltip: this.$translate('tooltip_ct_lost_key_insurance'),
                            disabled: this.$isGermany() || this.$isNorway(),
                            filterOutFunction: (card, model) => ! model.hasLostKeyInsurance
                        },
                        fuelInsurance: {
                            title: this.$translate('ct_wrong_fuel_insurance', 'Feltankningsförsäkring'),
                            active: false,
                            tooltip: this.$translate('tooltip_ct_wrong_fuel_insurance'),
                            disabled: this.$isGermany(),
                            filterOutFunction: (card, model) => ! model.hasWrongFuelInsurance
                        },
                    }
                },
                mobilePayments: {
                    show: false,
                    title: this.$translate('filter_payments_header', 'Betalningar'),
                    data: {
                        apple: {
                            title: 'Apple Pay',
                            active: false,
                            filterOutFunction: (card, model) => ! model.hasApplePay
                        },
                        google: {
                            title: 'Google Pay',
                            active: false,
                            filterOutFunction: (card, model) => ! model.hasGooglePay
                        },
                        samsung: {
                            title: 'Samsung Pay',
                            active: false,
                            filterOutFunction: (card, model) => ! model.hasSamsungPay
                        }
                    }
                },
                status: {
                    show: false,
                    disabled: ! this.$isGermany(),
                    title: this.$translate('filter_status_header', 'Status'),
                    data: {
                        gold: {
                            title: this.$translate('filter_card_status_gold', 'Gold'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_card_status_gold'),
                            filterOutFunction: (card, model) => ! model.isColorGold
                        },
                        platinum: {
                            title: this.$translate('filter_card_status_platinum', 'Platinum'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_card_status_platinum'),
                            filterOutFunction: (card, model) => ! model.isColorPlatinum
                        },
                        black: {
                            title: this.$translate('filter_card_status_black', 'Black'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_card_status_black'),
                            filterOutFunction: (card, model) => ! model.isColorBlack
                        },
                    }
                },
                application: {
                    show: false,
                    disabled: ! this.$isGermany(),
                    title: this.$translate('filter_application_header', 'Ansökan'),
                    data: {
                        postalIdentification: {
                            title: this.$translate('filter_postal_identification', 'Brev identification'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_postal_identification'),
                            filterOutFunction: (card, model) => ! model.hasPostalIdentification
                        },
                        videoIdentification: {
                            title: this.$translate('filter_video_identification', 'Video identification'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_video_identification'),
                            filterOutFunction: (card, model) => ! model.hasVideoIdentification
                        },
                        withoutSchufa: {
                            title: this.$translate('filter_without_schufa', 'Utan Schufa'),
                            active: false,
                            tooltip: this.$translate('tooltip_filter_without_schufa'),
                            filterOutFunction: (card, model) => ! model.withoutSchufa
                        }
                    }
                },
                recommended: {
                    show: false,
                    title: this.$translate('filter_recommended_cards_header', 'Rekommenderade'),
                    data: {
                        recommended: {
                            title: this.$translate('filter_recommended_cards', 'Rekommenderade kort'),
                            active: this.slice.primary.disable_preselect_recommended ? false : true,
                            tooltip: this.$translate('tooltip_filter_recommended_cards'),
                            filterOutFunction: (card,) => ! card.data.redirect_enabled || card.data.lists_disabled
                        }
                    }
                }
            }
        };
    },
    computed: {
        filter() {
            return Object.fromEntries(
                Object.entries(this.allfilters)
                    .filter(([, value]) => !value.disabled)
                    .map(([key, filterCategory]) => {
                        filterCategory.data = Object.fromEntries(
                            Object.entries(filterCategory.data).filter(([, value]) => {
                                return !value.disabled;
                            })
                        );
                        return [key, filterCategory];
                    })
            );
        },
        activeFilters() {
            return Object.values(this.filter)
                .map(category => Object.values(category.data).filter(filter => filter.active))
                .filter(category => category.length > 0)
                .flat()
                .map(filter => filter.title)
                .sort()
                .join(', ');
        },
        filterButtons() {
            let array = [];
            Object.keys(this.filter).forEach(key => {
                array.push({
                    key: key,
                    title: this.filter[key].title
                });
            });

            return array;
        },
        filteredCards() {

            const filterFunctions = Object.values(this.filter)
                .map(category => Object.values(category.data).filter(filter => filter.active))
                .filter(category => category.length > 0)
                .flat()
                .map(filter => filter.filterOutFunction);

            return Object.values(this.$store.state.cards)
                .filter(card => {
                    const model = new this.$models.CreditCard(card);

                    for (let i = 0; i < filterFunctions.length; i++) {
                        if (filterFunctions[i](card, model)) {
                            return false;
                        }
                    }

                    return true;
                });
        },
        sortedCards() {
            let cards = Object.values(this.filteredCards);

            let arr = [];
            let maxSalesCount = 0;
            let maxConversionRate = 0;
            let maxEpc = 0;

            cards.forEach(c => {
                const card = Object.assign({}, c);
                card.model = new this.$models.CreditCard(card);

                maxEpc = card.model._core.sales_stats.epc > maxEpc ? card.model._core.sales_stats.epc : maxEpc;
                maxSalesCount = card.model._core.sales_stats.sales > maxSalesCount ? card.model._core.sales_stats.sales : maxSalesCount;
                maxConversionRate = card.model._core.sales_stats.conversion_rate > maxConversionRate ? card.model._core.sales_stats.conversion_rate : maxConversionRate;

                arr.push(card);
            });

            arr.forEach(card => {
                
                if (! card.data.redirect_enabled) {
                    card.order = card.model._ratings.average_score ?? 0;

                    return;
                }

                card.order = 10000;

                if (card.model._core.sales_stats.sales === 0) {
                    card.order += card.model._core.sales_stats.clicks * -1;
                }
                else if (card.model._core.sales_stats.sales < 5) {
                    card.order +=
                        card.model._core.sales_stats.epc / maxEpc +
                        card.model._core.sales_stats.sales / maxSalesCount +
                        card.model._core.sales_stats.conversion_rate / maxConversionRate;
                }
                else {
                    card.order +=
                        (8 * (card.model._core.sales_stats.epc / maxEpc)) +
                        card.model._core.sales_stats.sales / maxSalesCount +
                        card.model._core.sales_stats.conversion_rate / maxConversionRate;
                }
            });

            return arr.slice(0).sort((a, b) => b.order - a.order);
        },
        list() {

            let cards = this.sortedCards;
            const insert = this.slice.items
                .filter(item => item.card?.id)
                .filter(item => (! item.until) || Date.now() <= new Date(item.until))
                .sort((a, b) => {
                    return parseInt(a.at_position) - parseInt(b.at_position);
                }); 

            if (insert.length) {

                // ok to add 
                let cardsToInsert = [];

                // remove from list 
                insert.forEach(cardToInsert => {
                    const before = cards.length;
                    cards = cards.filter(card => card.id !== cardToInsert.card.id);
                    const after = cards.length;
                    
                    if (before !== after) {
                        cardsToInsert.push(cardToInsert);
                    }
                });

                cardsToInsert.forEach(cardToInsert => {
                    cards.splice(parseInt(cardToInsert.at_position) -1, 0, cardToInsert.card);
                });
            }

            return cards;
            
        },
        totalCardCount() {
            return Object.values(this.$store.state.cards).length;
        },
        title() {
            return this.$prismic.asText(this.slice.primary.list_title);
        },
        info() {
            return this.$prismic.asText(this.slice.primary.list_info);
        }
    },
    watch: {
        activeFilters() {
            this.$gtm.push({
                event: 'cardsFiltered',
                term: this.activeFilters
            });
        }
    },
    methods: {
        toggle(key) {

            const update = ! this.filter[key].show;
            Object.keys(this.filter)
                .forEach(key => {
                    this.filter[key].show = false;
                });
            
            this.filter[key].show = update;
        },
        countFilters(key) {
            return Object.values(this.filter[key].data)
                .filter(filter => filter.active)
                .length;
        },
    }
};
</script>
<style lang="scss" scoped>

    ul {
        width: 100%;
        @include grid(1, 40px);
    }

    h1,
    h2,
    p,
    ul,
    .filter-container {
        width: 100%;
        text-align: left;
        @include spacing(margin, 6, bottom);
    }

    .filter-container .toggle {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @include device(desktop) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 12px;
        }

        span {
            font-family: $fontText;
            text-align: right;
        }

        > * {
            margin: 0;
        }

        .toggles {
            display: flex;
            max-width: 100%;
            overflow-x: scroll;
            align-items: center;
            padding: 10px;
            box-sizing: border-box;

            @include device(desktop) {
                overflow-x: inherit;
                padding: 0;
                flex-wrap: wrap;
                row-gap: 10px;
            }

            .btn-filter {
                margin-right: 5px;
                white-space: nowrap;
                box-shadow: $bxs;
                display: inline-flex;

                @include device(desktop) {
                    box-shadow: $bxs;
                    display: inline-flex;
                }

                .count {
                    background-color: $pink;
                    color: white;
                    font-size: .7em;
                    width: 15px;
                    height: 15px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100px;
                    margin-left: 5px;
                }
            }
        }

        .cards-counter {
            margin-top: 10px;
            @include device(desktop) {
                margin-top: 0;
            }
        }
    }

    .filter {
        @include spacing(padding, 6);
        background: $lightgray;
        @include grid(1, 15px);
        margin-top: 20px;

        .checkbox-and-tooltip {
            @include flex;
            @include flex(start, center);
        }
    }

    .checkbox {
        font-family: $fontText;
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 25px;

        display: flex; align-items:center;

        input {
            position: absolute; 
            opacity: 0;
            cursor: pointer; 
            height: 0; width: 0;
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: white;

            &:after {
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        /* On mouse-over, add a grey background color */
        &:hover input ~ .checkmark {
            background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        input:checked ~ .checkmark {
            background-color: $pink;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */
         input:checked ~ .checkmark:after {
            display: block;
        }
    }
    
    .error {
        font-family: $fontText;
        background: $lightgray;
        padding: 20px;

        .fa-times-circle {
            color: $red;
            margin-right: 10px;
        }
    }
</style>
