<template>
    <div class="wrapper">
        <Slices :slices="document.data.body" />
        <AuthorityBox :document="document" :hreflangs="hreflangs" />
        <script type="application/ld+json" v-html="jsonld" />
    </div>
</template>

<script>
import AuthorityBox from '@/components/AuthorityBox.vue';
import Slices from '@/components/slices/Slices.vue';

export default {
    components: {
        Slices,
        AuthorityBox
    },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        },
        hreflangs: {
            type: Array,
            required: true,
            default: () => []
        },
        url: {
            type: String,
            required: false,
            default: String
        }
    },
    data() {

        let jsonld = {
            '@context': 'https://schema.org',
            '@type': 'Article',
            'mainEntityOfPage': {
                '@type': 'WebPage',
                'url': this.url
            },
            'headline': this.document.data.title,
            'dateModified': this.$format.date(this.document.last_publication_date),
        };

        if (this.document.data.author.data) {
            jsonld.author = {
                '@type': 'Person',
                'name': this.document.data.author.data.name
            };
        }

        if (this.document.data.image && this.document.data.image.url) {
            jsonld.image = this.document.data.image.url;
        }

        return {
            jsonld
        };
    }
};
</script>
