<template>
    <Section
        class="banner"
        width="sm"
    >
        <HeaderBanner
            :header="$prismic.asText(slice.primary.sb_h1)"
            :teaser="slice.primary.sb_teaser"
            align="center"
        />
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>
