<template>
    <Section
        class="banner banner-about"
        width="md"
    >
        <div class="container">
            <HeaderBanner
                :header="$prismic.asText(slice.primary.h1)"
                :teaser="slice.primary.teaser"
                align="left"
            />
        </div>

        <div class="container">
            <transition name="pop" appear>
                <div
                    class="play-video"
                    @click="show = true"
                >
                    <i class="fas no-m fa-play" />
                </div>
            </transition>
        </div>

        <div
            v-if="show"
            class="video"
            @click="show = false"
        >
            <iframe
                class="br-3"
                :src="slice.primary.video_url[0].text"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data () {
        return {
            show: false
        };
    },
    watch: {
        show() {
            if (this.show) document.body.style.overflow = 'hidden';
            else document.body.style.overflow = 'visible';
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        .section-inner {
            @include grid(1, 80px);

            @include device(pad) {
                @include grid(2, 40px);
            }

            .container {
                @include flex;
                width: 100%;

                &:last-child { cursor: pointer; }

                .play-video {
                    @include size(300px, 200px);
                    max-width: 100%;
                    @include flex;
                    @include border-radius(10px);
                    box-shadow: $bxs;
                    @include spacing(padding,5);
                    background: $pink;

                    @include device(desktop) {
                        @include size(400px, 250px);
                    }

                    i {
                        font-size: 52px;
                        color: $white;
                    }
                }
            }

            .video {
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                @include flex;
                z-index: 99;
                background: rgba(0,0,0,.5);

                iframe {
                    @include size(900px, 500px);
                    max-width: calc(100% - 20px);
                    @include border-radius(10px);
                }
            }
        }
    }

</style>
