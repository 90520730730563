<template>
    <li class="page-link">
        <nuxt-link
            v-if="link"
            :to="$prismic.linkResolver(link)"
            class="page-link__link"
        >
            <PrismicImage
                :img="image"
                :alt="text"
                w="60"
                h="60"
            />
            <span>{{ text }}</span>
        </nuxt-link>

        <div
            v-else
            class="page-link__link"
        >
            <PrismicImage
                :img="image"
                :alt="text"
                w="60"
                h="60"
            />
            <span>{{ text }}</span>
        </div>
    </li>
</template>

<script>
export default {
    props: {
        link: {
            type: Object,
            required: false,
            default: () => {}
        },
        text: {
            type: String,
            required: false,
            default: String
        },
        image: {
            type: Object,
            required: false,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
.page-link {

    &__link {
        @include spacing(padding, 4);
        height: 100%;
        text-align: center;
        @include flex(start);

        @include device(pad) {
            @include grid(1, 20px);
        }

        img {
            @include size(30px);
            @include border-radius(5px);
            margin-right: 20px;

            @include device(pad) {
                @include size(60px);
                display: block;
                margin-right: 0;
                margin: auto auto;
            }
        }

        span {
            color: $font;
            font-family: $fontHeader;
            @include font-size(20px);
        }
    }
}
</style>
