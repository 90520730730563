<template>
    <Section width="md">
        <card-calculator
            :pre-selected-card-id="slice.primary.default_card.id"
            :title="$prismic.asText(slice.primary.h2)"
            :description="slice.primary.text"
        />
    </Section>
</template>

<script>
import CardCalculator from '@/components/card-calculator/index.vue';
export default {
    components: {
        CardCalculator
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
};
</script>