export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        tableHeaders(){
            return Object.entries(this.slice.primary)
                .filter(([key, value]) => key.startsWith('table_row') && value)
                .map(([, value]) => value);
        },
        customContent() {
            return this.tableHeaders.map((header, index) => {
                return {
                    tableHeader: header,
                    tableValues: this.tableValues(index) 
                };
            });
        },
    },
    methods: {
        headerValue(rowIndex, cardIndex) {
            return this.slice.items[cardIndex][`table_row_data_${rowIndex + 1}`];
        },
        tableIcon(rowIndex, cardIndex) {
            return this.slice.items[cardIndex][`table_row_icon_${rowIndex + 1}`];
        },
        tableValues(rowIndex) {
            return this.slice.items.map((_item, cardIndex) => {
                return {data: this.headerValue(rowIndex, cardIndex), checkMark: this.tableIcon(rowIndex, cardIndex)};
            });
        },
    }
};