<template>
    <Section
        width="sm"
        :label="slice.slice_label"
    >
        <div
            class="rich-text"
            v-html="$prismic.asHtml(slice.primary.rt_content)"
        />
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>
