<template>
    <Section 
        :width="sliceWidth" 
        class="banner banner-image"    
    >
        <HeaderBanner
            :header="slice.primary.h1"
            :teaser="slice.primary.teaser"
            align="left"
            class="banner-image-header"
        />
        <prismic-image 
            v-if="slice.primary.image.url"
            :img="slice.primary.image"
            class="image"
            :w="imageWidth"
        />
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        sliceWidth() {
            return this.slice.primary.slice_width ?? 'sm';
        },
        imageWidth() {
            return this.slice.primary.slice_width === 'md' ? '1100' : '700';
        }
    }
};
</script>
<style lang="scss" scoped>
    .banner-image::v-deep {
        .banner-image-header.left {
            @include device(pad) {
                text-align: center;
                justify-content: center;
            }
        }
        .image {
            max-width: 100%;
            margin-top: 20px;
            @include device(pad) {
                margin-top: 30px;
            }
        }
    }
</style>