<template>
    <!-- NOTE: Deprecated?? -->
    <Section>
        <h2 class="black t-l width-full c-p-20 bg-lpink">{{ $prismic.asText(slice.primary.header) }}</h2>
        <p class="black t-l width-full c-p-20">{{ $prismic.asText(slice.primary.text) }}</p>

        <div v-for="item in slice.items" class="container width-full c-p-40">
            <h3 class="black t-l t-line-pink stretch">{{ $prismic.asText(item.header) }}</h3>
            <div class="no-style list--check black" v-html="$prismic.asHtml(item.list)" />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>
