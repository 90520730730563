<template>
    <nuxt-link
        v-if="card"
        :to="$prismic.linkResolver(card)"
        class="card-data"
    >
        <PrismicImage
            :img="card.data.card_image"
            w="220"
            h="140"
        />

        <h3>{{ card.data.title }}</h3>

        <CardStarsWithCount :data="model._ratings" />
    </nuxt-link>
</template>

<script>
import CardStarsWithCount from '@/components/stars/StarsWithCount.vue';
export default {
    components: { CardStarsWithCount },
    props: {
        id: {
            type: String,
            required: false,
            default: String
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.id];
        },
        model() {
            return new this.$models.CreditCard(this.card);
        }
    }
};
</script>

<style lang="scss" scoped>
    .card-data {
        @include grid(1, 20px);
        color: $font;
        text-align: center;

        &:hover { color: $pink; }

        img { margin: auto; }
    }
</style>
