<template>
    <Section
        class="banner mascot-banner"
        width="md"
    >
        <div class="container">
            <HeaderBanner
                :header="$prismic.asText(slice.primary.h1)"
                :teaser="slice.primary.text"
                align="left"
                :class="{'short-header': $prismic.asText(slice.primary.h1).length < 20}"
            />
        </div>

        <div class="container">
            <PrismicImage
                :alt="$prismic.asText(slice.primary.text)"
                :img="slice.primary.image"
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .mascot-banner::v-deep {
        .section-inner {
            @include grid(1,40px);

            @include device(pad) {
                @include grid(2,80px);
            }

            .container {
                &:first-child {
                    position: relative;
                    z-index: 2;
                    @include flex(start);

                    h1 {
                        @include device(mobile) {
                            max-width: 50%;
                        }
                    }
                    .banner-header {
                        @include device(mobile) {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }
                    .banner-header.short-header {
                        .banner-header__hr {
                            @include device(mobile) {
                                max-width: 50%;
                            }
                        }
                    }
                }

                &:last-child {
                    position: absolute;
                    right: 0;

                    @include device(pad) {
                        position: relative;
                    }

                    img {
                        @include size(200px, auto);

                        @include device(pad) {
                            @include size(100%, auto);
                            max-width: 600px;
                        }

                        @include device(desktop) {
                            max-width: none;
                        }
                    }
                }
            }
        }
    }
</style>
