<template>
    <Section width="md">
        <div
            v-animate="{threshold: .5, animation: 'slideright'}"
            class="container"
        >
            <span>{{ $prismic.asText(slice.primary.quote_text) }}</span>
            <span v-if="slice.primary.quote_by">- {{ slice.primary.quote_by }}</span>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        background: $lightpink;
        padding-top: 0;
        padding-bottom: 0;
        margin: 40px 0;
        @include device(pad) {
            margin: 48px 0;
        }

        .section-inner {
            position: relative;
            @include spacing(padding, 10, (top,bottom));

            &:after,
            &:before {
                font-family: "Font Awesome 6 Pro";
                font-weight: 900;
                position: absolute;
                font-size: 32px;

                @include device(1100px) {
                    font-size: 48px;
                }
            }

            @include psuedo(before, "\f10e") {
                top: -10px;
                left: 20px;

                @include device(1100px) {
                    top: -20px;
                    left: -100px;
                }
            }

            @include psuedo(after, "\f10d") {
                bottom: -10px;
                right: 20px;

                @include device(1100px) {
                    bottom: -20px;
                    right: -100px;
                }
            }
        }
    }

    span {
        width: 100%;
        display: block;
        text-align: center;

        &:first-child {
            @include font-size(22px, 12px);
            font-family: $fontHeader;

            @include device(pad) {
                @include font-size(32px, 12px);
            }
        }

        &:last-child {
            @include font-size(18px);
            font-family: $fontText;
            @include spacing(margin, 4, top);
        }
    }
</style>
