<template>
    <Section
        id="userreview"
        width="sm"
    >
        <div class="container-header">
            <div class="container-header__inner">
                <h2>{{ $translate('ucr_header', 'Användarrecensioner') }}</h2>
                <span>{{ $format.number(ratings.average_score, 1) }} {{ $translate('ucr_average_rating', 'i snittbetyg') }}</span>
            </div>

            <Button
                v-if="showForm"
                v-scroll-to="'#rating'"
                :text="$translate('ucr_scroll_to_form', 'Lämna betyg')"
                icon="fas fa-star-half-alt"
                class="header-button"
                size="sm"
                background="pink"
            />
        </div>

        <div
            v-if="ratings.data && ratings.data.length"
            class="container-list"
        >
            <ul class="list">
                <li
                    v-for="(comment, index) in ratingsToShow"
                    :key="index"
                    class="list-item"
                >
                    <div class="list-item__header">
                        <span class="header-name">{{ comment.rater_name }}</span>
                        <Stars
                            class="header-score"
                            :rating="comment.rating_score"
                        />
                    </div>
                    <p class="list-item__posted">{{ comment.posted_at.split("T")[0] }}</p>
                    <div class="list-item__comment" v-html="$format.comment(comment.rating_body)" />

                    <div 
                        v-if="comment.reply"
                        class="reply"
                    >
                        <span class="header-name border-bottom">{{ comment.reply.author_name }}</span>
                        <p class="list-item__posted">{{ comment.reply.created_at.split("T")[0] }}</p>
                        <div class="list-item__comment rich-text" v-html="$format.comment(comment.reply.comment_body)" />
                    </div>
                </li>
            </ul>

            <Button
                v-if="postNumber >= postLimit"
                :text="$translate('show_more', 'Visa fler')"
                icon="fas fa-chevron-down"
                background="pink"
                size="sm"
                class="show-more-btn"
                @click="postLimit = postLimit + 10"
            />
        </div>

        <div
            id="rating"
            class="container-form"
        >
            <form
                v-if="showForm"
                class="form"
                @submit.prevent="submitRating"
            >
                <span class="container-form__header">{{ $translate('ucr_form_title', 'Vad tycker du om {card}?').replace('{card}', title) }}</span>

                <div class="container-form__container">
                    <label for="body">{{ $translate('ucr_form_body_title', 'Recension') }}</label>
                    <textarea
                        id="body"
                        v-model="ratingBody.value"
                        name="body"
                        rows="7"
                    />
                    <div v-if="ratingBody.errorActive" class="input-error">
                        {{ ratingBody.errorText }}
                    </div>
                </div>

                <div class="container-form__container">
                    <label>{{ $translate('ucr_form_your_rating', 'Ditt betyg') }}</label>
                    <div class="input rating">
                        <i :class="[{ratingSet: ratingScore.value}, {active: ratingScoreHover}]" class="fas fa-star" @click="ratingScore.value = 1" @mouseenter="ratingScoreHover = 1" @mouseleave="ratingScoreHover = undefined" />
                        <i :class="[{ratingSet: ratingScore.value > 1}, {active: ratingScoreHover > 1}]" class="fas fa-star" @click="ratingScore.value = 2" @mouseenter="ratingScoreHover = 2" @mouseleave="ratingScoreHover = undefined" />
                        <i :class="[{ratingSet: ratingScore.value > 2}, {active: ratingScoreHover > 2}]" class="fas fa-star" @click="ratingScore.value = 3" @mouseenter="ratingScoreHover = 3" @mouseleave="ratingScoreHover = undefined" />
                        <i :class="[{ratingSet: ratingScore.value > 3}, {active: ratingScoreHover > 3}]" class="fas fa-star" @click="ratingScore.value = 4" @mouseenter="ratingScoreHover = 4" @mouseleave="ratingScoreHover = undefined" />
                        <i :class="[{ratingSet: ratingScore.value > 4}, {active: ratingScoreHover > 4}]" class="fas fa-star" @click="ratingScore.value = 5" @mouseenter="ratingScoreHover = 5" @mouseleave="ratingScoreHover = undefined" />
                    </div>
                    <div v-if="ratingScore.errorActive" class="input-error">
                        {{ ratingScore.errorText }}
                    </div>
                </div>

                <div class="container-form__container">
                    <label for="name">{{ $translate('ucr_form_your_name', 'Ditt namn') }}</label>
                    <input
                        id="name"
                        v-model="raterName.value"
                        class="padding-2 width-full"
                        type="text"
                        name="name"
                    >
                    <div v-if="raterName.errorActive" class="input-error">
                        {{ raterName.errorText }}
                    </div>
                </div>

                <div class="container-form__container">
                    <label for="email">{{ $translate('ucr_form_your_email', 'Ditt betyg(publiceras inte)') }}</label>
                    <input
                        id="email"
                        v-model="raterEmail.value"
                        class="padding-2 width-full"
                        type="email"
                        name="email"
                    >
                    <div v-if="raterEmail.errorActive" class="input-error">
                        {{ raterEmail.errorText }}
                    </div>
                </div>

                <Button
                    :text="$translate('ucr_form_submit', 'Skicka in')"
                    icon="fas fa-share"
                    size="md"
                    class="container-form__submit"
                    background="pink"
                    @click="submitRating"
                />
            </form>

            <div
                v-else-if="ratingSent"
                class="rating-sent"
            >
                {{ $translate('ucr_form_thank_you', 'Tack för ditt betyg. Den kommer att synas här på sajten när vi godkänt den.') }}
            </div>
        </div>
    </Section>
</template>
<script>
import Stars from '@/components/stars/Stars.vue';
export default {
    components: { Stars },
    props: {
        title: {
            type: String,
            required: true
        },
        ratings: {
            type: Object,
            required: true
        },
        brandId: {
            type: Number,
            required: true
        },
        inactive: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showForm: !this.inactive,
            ratingBody: {
                value: '',
                errorActive: false,
                errorText: this.$translate('ucr_form_body_error', 'Fyll i din recension'),
                validate: (input) => {
                    return !!input;
                }
            },
            ratingScore: {
                value: undefined,
                errorActive: false,
                errorText: this.$translate('ucr_form_your_rating_error', 'Fyll i ditt betyg'),
                validate: (input) => {
                    return !!input;
                }
            },
            ratingScoreHover: undefined,
            raterName: {
                value: '',
                errorActive: false,
                errorText: this.$translate('ucr_form_your_name_error', 'Fyll i ditt namn'),
                validate: (input) => {
                    return !!input;
                }
            },
            raterEmail: {
                value: '',
                errorActive: false,
                errorText: this.$translate('ucr_form_your_email_error', 'Fyll i en giltig email'),
                validate: (input) => {
                    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
                    return input && reg.test(input);
                }
            },
            ratingSent: false,
            postLimit: 10,
            postNumber: this.ratings.data.length
        };
    },
    computed: {
        reversedRatings() {
            return [...this.ratings.data].reverse();
        },
        ratingsToShow() {
            return this.reversedRatings.filter(
                (rating, index) => index <= this.postLimit
            );
        },
        sourceId() {
            return parseInt(process.env.UCR_SOURCE_ID);
        }
    },
    methods: {
        submitRating() {
            const inputValues = [this.raterName, this.raterEmail, this.ratingScore, this.ratingBody];
            inputValues.forEach(input => {
                input.errorActive = ! input.validate(input.value);
            });
            const formValidated = ! inputValues.some(input => input.errorActive);

            if (! formValidated) {
                return;
            }

            inputValues.forEach(input => {
                input.errorActive = false;
            });
            this.showForm = false;
            this.ratingSent = true;

            const payload = {
                brand_id: this.brandId,
                source_id: this.sourceId,

                rater_name: this.raterName.value,
                rater_email: this.raterEmail.value,
                rating_score: this.ratingScore.value,
                rating_body: this.ratingBody.value,
            };

            this.$axios.$post('https://api.core.compary.com/api/v0/ratings', payload)
                .then(() => {})
                .catch((e) => {
                    console.log(e);
                });
            
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    .section-inner {
        .container-header {
            width: 100%;
            @include flex(between);
            margin-bottom: 40px;

            &__inner {
                @include device(mobile) { margin-bottom: 20px; }

                span {
                    font-family: $fontText;
                    display: block;
                    margin-top: 10px;
                    @include font-size(24px);
                }
            }
        }

        .container-list {
            .list {
                @include grid(1, 30px);

                &-item {
                    padding-bottom: 30px;
                    @include border(bottom);
                    @include grid(1,10px);

                    &__header {
                        width: 100%;
                        @include flex(between);

                        .header-name {
                            font-family: $fontHeader;
                            @include font-size(24px);
                        }

                        .header-score {
                            width: auto;
                            i { font-size: 18px; }
                        }
                    }

                    &__posted {
                        opacity: .6;
                        font-style: italic;
                        font-size: 14px;
                    }

                    .reply {
                        background: $lightgray;
                        margin-top: 15px;
                        padding: 15px;

                        .header-name {
                            font-family: $fontHeader;
                            @include font-size(16px);
                        }
                    }
                }
            }

        }
        
        .header-button, .show-more-btn {
            margin-top: 30px;
        }

        .container-form {
            margin-top: 30px;
            padding-top: 30px;
            @include border(top);

            .form {
                @include grid(1, 30px);
            }

            &__header {
                display: block;
                font-family: $fontHeader;
                @include font-size(32px);
            }

            &__container {
                @include grid(1, 10px);

                input,
                textarea,
                .input {
                    padding: 15px;
                    @include border;
                    box-sizing: border-box;
                    width: 100%;
                    @include border-radius(3px);
                    box-shadow: inset rgba(99, 99, 99, 0.15) 0px 2px 8px 0px;
                }

                textarea { resize: vertical; }

                label { font-family: $fontText; }

                .active { color: $yellow!important; }
                .ratingSet { color: orange; }
                .input-error {
                    color: #8b0000;
                    background: rgba(179,0,0,.15);
                    padding: 7px;
                    font-size: 13px;
                    margin-top: -5px;
                }
            }

            .rating-sent {
                font-family: $fontHeader;
                @include font-size(32px);
                width: 100%;
            }
        }
    }
}
</style>
