<template>
    <Section
        v-if="features.length"
        id="features"
        width="md"
        class="features"
    >
        <h2>{{ $translate('features', 'Egenskaper') }}</h2>

        <ul class="list-page-links">
            <PageLink
                v-for="(iterator, index) in features"
                :key="index"
                :data="iterator"
                :image="iterator.feature.data.image"
                :text="iterator.feature.data.title"
            />
        </ul>
    </Section>
</template>

<script>
import PageLink from '@/components/PageLink.vue';
export default {
    components: { PageLink },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            features: this.document.data.features.filter(this.$filterEmptyRelations).sort(this.sortFeatures)
        };
    },
    methods: {
        sortFeatures: function(a, b) {
            if (a.feature.data.title < b.feature.data.title) {
                return -1;
            }

            if (a.feature.data.title > b.feature.data.title) {
                return 1;
            }

            return 0;
        }
    }
};
</script>

<style lang="scss" scoped>
    h2 { @include spacing(margin,8,bottom);}

    li { background: $lightgray; }
</style>
