<template>
    <!-- TODO Half ratings -->
    <div v-if="enabled">
        <!-- NOTE: </i></i> must be without space or anything, otherwise html will
                treat is as a blank space and not keep the stars aligned. -->
        <i
            v-for="i in rating"
            :key="`fullstar${i}`"
            class="full"
        /><i
            v-for="i in (5 - rating)"
            :key="`halfstar${i}`"
            class="half"
        />
    </div>
</template>

<script>
export default {
    props: {
        starClass: {
            type: String,
            required: false,
            default: 'pink'
        },
        rating: {
            type: Number,
            required: true
        }
    },
    computed: {
        enabled() {
            return ! this.$isNorway();
        }
    }
};
</script>
<style lang="scss" scoped>
    div {
        width: 100%;

        i {
            font-size: 24px;
            color: $pink;
        }

        .half {
            position: relative;
            &:after {
                font-family: "Font Awesome 6 Pro";
                font-style: normal;
                position: relative;
                content: "\f005";
                margin: 0;
            }
        }

        .full {
            position: relative;
            &:after {
                font-family: "Font Awesome 6 Pro";
                font-weight: bold;
                font-style: normal;
                position: relative;
                content: "\f005";
                margin: 0;
            }
        }
    }
</style>
