<template>
    <div class="feedback-container">
        <expander :title="$translate('guide_feedback_expand_button', 'Lämna feedback')">
            <div class="feedback-box">
                <div v-if="!success">
                    <span class="description">
                        {{ $translate('guide_feedback_description', 'Är du nöjd med resultaten du fick uteifrån dina svar? Hjälp oss förbättra guiden genom att dela med om din åsikt!') }} 
                    </span>
                    <div class="feedback-thumbs" :class="opinion ? `selected selected-${opinion}` : ''">
                        <i class="fas fa-thumbs-up fa-2x positive" :class="{'active': opinion === 'positive'}" @click="setOpinion('positive')" />
                        <i class="fas fa-thumbs-down fa-2x negative" :class="{'active': opinion === 'negative'}" @click="setOpinion('negative')" />
                    </div>
                    <div v-if="opinion" class="feedback-form">
                        <div>
                            <label for="feedback">{{ formLabelText }}</label>
                            <textarea v-model="feedback" rows="3" name="feedback" class="feedback-textarea" />
                        </div>
                        <Button
                            :text="$translate('guide_feedback_submit_button', 'Skicka in')"
                            size="md"
                            class="submit-button"
                            background="pink"
                            @click="submit()"
                        />
                    </div>
                    <div v-if="error" class="error">
                        <span>{{ $translate('guide_feedback_error_message', 'Något gick fel, försök igen senare.') }}</span>
                    </div>
                </div>
                <div v-else>
                    <div class="success-text rich-text" v-html="$prismic.asHtml($translate('guide_feedback_success_message'))" />
                </div>
            </div>
        </expander>
    </div>
</template>

<script>
import Expander from '@/components/Expander.vue';
export default {
    components: {
        Expander
    },
    data() {
        return {
            opinion: '',
            feedback: '',
            success: false,
            loading: false,
            error: false
        };
    },
    computed: {
        formLabelText() {
            if (this.opinion === 'positive') {
                return this.$translate('guide_feedback_positive_input_label', 'Vad tyckte du var bra? (frivilligt)');
            }
            return this.$translate('guide_feedback_negative_input_label', 'Vad tycker du vi kan förbättra? (frivilligt)');
        }
    },
    methods: {
        submit() {
            const payload = {
                opinion: this.opinion,
                feedback: this.feedback
            };
            if (! this.loading && this.opinion) {
                this.loading = true;
                this.error = false;
            
                this.$axios.post('https://formspree.io/f/moqoqlnj', payload)
                    .then(() => {
                        this.success = true;
                        this.loading = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line no-console
                        console.log(e);
                        this.loading = false;
                        this.error = true;
                    });
            }
        },
        setOpinion(opinion) {
            this.feedback = '';
            if (opinion === this.opinion) {
                return this.opinion = '';
            }
            this.opinion = opinion;
        }
    }
};
</script>

<style lang="scss" scoped>
    .feedback-container ::v-deep {
        font-family: $fontText;

        .expander-content-wrapper {
            box-shadow: $bxs;
            @include border-radius(5px);
        }
        .feedback-box {
            padding: 20px;
        }
        .description {
            @include font-size(16px);
        }
    
        .feedback-thumbs {
            margin: 20px 0;
            display: flex;
            gap: 10px;
            i {
                padding: 16px;
                border: 1px solid #e6e6e6;
                border-radius: 100px;
                box-shadow: $btnShadow;
                &:hover{box-shadow: none;}
                cursor: pointer;
                background: $white;
                transition: box-shadow 200ms ease, background 200ms ease, color 200ms ease, transform 200ms ease-out;
            }
            &.selected i {
                box-shadow: none;
                &.negative {
                    transform: translateX(calc(-100% - 10px));
                }
            }
            &.selected-positive i.positive {
                background: $green;
                color: $white;
                z-index: 1;
                position: relative;
            }
            &.selected-negative i.negative {
                background: $red;
                color: $white;
                z-index: 1;
                position: relative;
            }
        }
}
    .feedback-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        label {
            @include font-size(14px);
            margin-bottom: 10px;
            display: inline-block;
        }
        .feedback-textarea {
            font-family: $fontText;
            padding: 15px;
            resize: vertical;
            @include border;
            box-sizing: border-box;
            width: 100%;
            @include border-radius(3px);
            box-shadow: inset rgba(99, 99, 99, 0.15) 0px 2px 8px 0px;
        }
        .submit-button {
            margin-top: 10px;
            margin-left: auto;
        }
    }
    .success-text ::v-deep {
        p {
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .error {
        margin-top: 10px;
        color: darkred;
        font-size: 14px;
        background: rgba(179, 0, 0, 0.15);
        padding: 15px;
        border: 1px solid darkred;
        border-radius: 2px;
        display: flex;
        justify-content: center;
    }
</style>