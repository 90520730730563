<template>
    <ul class="pros-list">
        <li v-for="(pro, proIndex) in pros" :key="proIndex">
            <i class="fa-solid fa-check-circle icon" />
            <div class="pro rich-text" v-html="$prismic.asHtml(pro)" />
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        pros: {
            type: Array,
            required: true
        }
    }
};
</script>
<style lang="scss" scoped>
    .pros-list {
        margin-top: 1rem;
        li {
            display: flex;
            align-items: baseline;
            margin-bottom: 0.5rem;
            .icon {
                margin-right: 0.5em;
                color: $green;
            }
            .pro {
                display: inline;
                &::v-deep * {
                    font-family: $fontText;
                    display: inline;
                }
            }
        }
    }
</style>