<template>
    <td>
        <div class="table-data-header increased-spacing">{{ dataHeader }}</div>
        <ul>
            <li
                v-for="(item, index) in data"
                :key="index"
                :class="listType"
            >
                {{ item }}
            </li>
        </ul>
    </td>
</template>

<script>
export default {
    props: {
        dataHeader: {
            type: String,
            required: true
        },
        data: {
            type: Array,
            required: true,
        },
        dataType: {
            type: String,
            required: true
        },
        listType: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
    ul {
        @include grid(1, 10px);

        li {
            padding-left: 25px;
            position: relative;

            &:before {
                font-family: "Font Awesome 6 Pro";
                font-weight: 900;
                position: absolute;
                left: 0;

            }
        }

        .demand::before {
            content: "\f4fc";
            color: #00c985;
        }

        .pros::before {
            content: "\f058";
            color: #00c985;
        }

        .cons::before {
            content: "\f057";
            color: #d21433;
        }
    }
</style>
