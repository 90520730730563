<template>
    <Section width="lg">
        <ul class="list">
            <Link
                v-for="(post, index) in allPosts"
                :key="index"
                :post="post"
            />
        </ul>

        <div v-if="allPosts.length" class="no-style container width-full">
            <!-- NOTE: Client only needed? Otherwise we get a warning
					but maybe possible to do better..? -->
            <client-only>
                <infinite-loading
                    spinner="spiral"
                    @infinite="infiniteScroll"
                >
                    <template #no-more>
                        <!-- NOTE: Is display:none; really the best way to do this??? -->
                        <div style="display: none">Done.</div>
                    </template>
                </infinite-loading>
            </client-only>
        </div>
    </Section>
</template>

<script>
import Link from '@/components/slices/latest-news/LatestNewsLink.vue';
export default {
    components: { Link },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            allPosts: [],
            page: 1
        };
    },
    async fetch() {
        const predicates = [
            this.$prismic.predicates.at('document.type', 'post'),
        ];

        this.allPosts = (await this.$prismic.api.query(predicates,
            { 
                orderings: '[document.first_publication_date desc]',
                lang: process.env.PRISMIC_LANG,
                pageSize: 25,
            }
        )).results;
    },
    fetchKey() {
        return `all-posts-${this.slice.id}`;
    },
    methods: {
        async infiniteScroll($state) {
            this.page++;
            const response = await this.$prismic.api.query(
                this.$prismic.predicates.at('document.type', 'post'), {
                    lang: process.env.PRISMIC_LANG,
                    pageSize: 25,
                    page: this.page,
                    orderings: '[document.first_publication_date desc]'
                }
            );
            if (response.results_size > 0) {
                this.allPosts = this.allPosts.concat(response.results);
            }
            $state.loaded();
            if (response.total_pages <= response.page) { 
                $state.complete(); 
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .list {
        @include grid(1, 20px);

        @include device(pad) {
            @include grid(2, 20px);
        }

        @include device(desktop) {
            @include grid(3, 20px);
        }
    }
</style>
