<template>
    <Section
        width="lg"
        :label="slice.slice_label"
        :class="background"
        class="content-text"
    >
        <h2 
            v-if="$prismic.asText(slice.primary.h2)" 
            class="content-text-h2" 
        >
            {{ $prismic.asText(slice.primary.h2) }}
        </h2>
        <div 
            v-if="$prismic.asText(slice.primary.h2)" 
            class="content-text-teaser rich-text"
            v-html="$prismic.asHtml(slice.primary.text)"
        />

        <div
            v-for="(iterator, index) in slice.items"
            :key="index"
            class="container rich-text"
            :class="[iconColor(index), boxColor(index), {'full-width': isSingleBox}]"
            v-html="$prismic.asHtml(iterator.box_text)"
        />
    </Section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        background() {
            return {
                'pink': 'bg-lpink',
                'gray': 'bg-lgray',
                'blue': 'bg-lblue',
            }[this.slice.primary.background] || 'bg-white';
        },
        isSingleBox() {
            return this.slice.items.length === 1;
        }
    },
    methods: {
        iconColor(index) {
            const iconColor = this.slice.items[index].icon_color;
            if (iconColor) {
                return `icon-color-${iconColor}`;
            }
            return '';
        },
        boxColor(index) {
            const boxColor = this.slice.items[index].box_color;
            return {
                'pink': 'bg-lpink',
                'gray': 'bg-lgray',
                'blue': 'bg-lblue',
            }[boxColor] || 'bg-white';
        }
    }
};
</script>
<style lang="scss" scoped>
.section.bg-lpink,
.container.bg-lpink {
    background: $lightpink;
}
.section.bg-lgray,
.container.bg-lgray {
    background: $lightgray;
}
.section.bg-lblue,
.container.bg-lblue {
    background: $lightblue;
}
.section.bg-white,
.container.bg-white {
    background: $white;
}

section::v-deep {
    &.content-text {
        margin: 40px 0;
        @include device(pad) {
            margin: 48px 0;
        }
    }
    .section-inner {
        .content-text-h2,
        .content-text-teaser {
            @include spacing(margin, 2, (left,right));
            @include spacing(margin, 6, bottom);
            width: 100%;
        }
        @include flex(start, stretch);
            
        .container {
            width: calc(100%);
            margin: 0 0 20px 0;
            @include border-radius(5px);
            @include spacing(padding, 5);
            box-shadow: $bxs;

            @include device(desktop) {
                width: calc(50% - 20px);
                margin: 10px;
            }

            p:first-child {
                margin-top: 0;
            }
        }
        .container.full-width {
            width: 100%;
        }
        .container.icon-color-green ul li:after {
            background-color: $green;
        }
        .container.icon-color-pink ul li:after {
            background-color: $pink;
        }
    }
}
</style>
