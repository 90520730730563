<template>
    <tr>
        <th>{{ fee.title }}</th>
        <td v-for="(cardFee, index) in fee.data" :key="index" :class="{highlight: checkLowest(fee.key, allCards[index])}">
            <span>{{ cardFee }}</span>
        </td>
    </tr>
</template>
<script>
export default {
    props: {
        fee: {
            type: Object,
            required: true
        },
        allCards: {
            type: Array,
            required: true
        },
        checkLowest: {
            type: Function,
            required: true
        }
    }
};
</script>