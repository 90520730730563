<template>
    <div id="result" class="guide-result">
        <template 
            v-if="hasPaymentRemarks && 
                (paymentRemarksLenders.length > 0 || paymentRemarksCards.length > 0)"
        >
            <h2 class="payment-remarks-results-header">{{ $translate('guide_result_header', 'Ditt resultat') }}</h2>
            <p>
                {{ $translate(
                    'guide_payment_remarks_result_description', 
                    'Inga kreditkort accepterar betalningsanmärkningar, här får du därför lite andra alternativ.'
                ) }}
            </p>
            <template v-if="paymentRemarksLenders.length > 0">
                <h3 class="payment-remarks-results-sub-header">
                    {{ $translate(
                        'guide_payment_remarks_result_lenders_header', 
                        'Kontokrediter som passar dig'
                    ) }}
                </h3>
                <ul class="lenders-list">
                    <lender 
                        v-for="lender in paymentRemarksLenders"
                        :key="lender.id"
                        :lender="lender"
                    />
                </ul>
            </template>
            <template v-if="paymentRemarksCards.length > 0">
                <h3 class="payment-remarks-results-sub-header">
                    {{ $translate(
                        'guide_payment_remarks_result_cards_header', 
                        'Betalkort som passar dig'
                    ) }}
                </h3>
                <ul class="card-list">
                    <card
                        v-for="(card, index) in paymentRemarksCards"
                        :id="card.id"
                        :key="index"
                    />
                </ul>
            </template>
        </template>
        <template v-else>
            <h2 class="results-header">{{ $translate('guide_result_header', 'Ditt resultat') }}</h2>
            <ul class="card-list">
                <card
                    v-for="(card, index) in resultList"
                    :id="card.id"
                    :key="index"
                    :highlighted-text="index === 0 ? $translate('guide_best_match', 'Bästa matchning') : ''"
                />
            </ul>
        </template>
        <div class="under-list-utilities">
            <feedback-box />
            <Button
                :text="$translate('guide_redo_test_button', 'Gör om test')"
                size="md"
                class="btn-redo-guide"
                background="pink"
                @click="emitRedoGuide()"
            />
        </div>
    </div>
</template>

<script>
import Card from '@/components/cards/CreditCard.vue';
import Lender from '@/components/cards/Lender.vue';
import FeedbackBox from '@/components/card-guide/FeedbackBox.vue';
export default {
    components: {
        Card,
        Lender,
        FeedbackBox
    },
    props: {
        hasPaymentRemarks: {
            type: Boolean,
            required: false,
            default: false
        },
        paymentRemarksCards: {
            type: Array,
            required: false,
            default: () => []
        },
        paymentRemarksLenders: {
            type: Array,
            required: false,
            default: () => []
        },
        resultList: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    methods: {
        emitRedoGuide() {
            this.$emit('redoGuide');
        }
    }
};
</script>